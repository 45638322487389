<template lang="pug">
.state-tag
  span(:class="`state-tag-content ${typeClass}`") {{ $isMobile() ? '' : text }}
</template>
<script>
export const TAG_TYPES = {
  INFO: "info",
  SUCCESS: "success",
  DANGER: "danger",
};

export default {
  props: {
    text: {
      type: String,
      default: undefined,
    },
    tagType: {
      type: String,
      default: TAG_TYPES.INFO,
      validator: (value) => Object.values(TAG_TYPES).includes(value),
    },
  },
  computed: {
    typeClass() {
      return `${this.tagType}-state`;
    },
  },
};
</script>
<style scoped lang="scss">
.state-tag {
  .state-tag-content {
    position: relative;
    &::before {
      @apply absolute content-space rounded-full;
      @apply w-3 h-3;
      @apply top-1.5 sm:top-1;
      @apply -left-4 sm:-left-6;
    }
    &.info-state::before {
      @apply bg-gray-300;
    }
    &.danger-state::before {
      @apply bg-failure;
    }
    &.success-state::before {
      @apply bg-success;
    }
  }
  &.split {
    .state-tag-content {
      &::before {
        @apply top-3 sm:top-1;
        @apply left-0 sm:-left-6;
      }
    }
  }
}
</style>
