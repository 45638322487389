<script>
export default {
  name: "IconCircleWarning",
};
</script>
<template lang="pug">
.pledg-icon.icon-circle-warning
  svg.svg-icon(viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg")
    path(d="M24.5227 31.0751C23.9819 31.0751 23.539 30.6451 23.5231 30.1045L23.2195 19.7778C23.203 19.2143 23.6553 18.7484 24.2191 18.7484H25.7034C26.268 18.7484 26.7206 19.2156 26.7029 19.7799L26.3776 30.1065C26.3606 30.6463 25.9181 31.0751 25.3781 31.0751H24.5227ZM25.027 36.9424C24.4058 36.9424 23.8968 36.7537 23.4999 36.3763C23.1203 35.9989 22.9304 35.5442 22.9304 35.0124C22.9304 34.4634 23.1203 34.0002 23.4999 33.6227C23.8968 33.2453 24.4058 33.0566 25.027 33.0566C25.6137 33.0566 26.0969 33.2453 26.4765 33.6227C26.8734 34.0002 27.0718 34.4634 27.0718 35.0124C27.0718 35.5442 26.8734 35.9989 26.4765 36.3763C26.0969 36.7537 25.6137 36.9424 25.027 36.9424Z" fill="white")
    path(d="M31.8628 6.23664C29.0462 0.587789 20.9537 0.587784 18.1372 6.23664L3.8065 34.978C1.26935 40.0665 5.00197 46 10.6693 46H39.3307C44.998 46 48.7307 40.0664 46.1935 34.978L31.8628 6.23664Z" stroke="white" stroke-width="4")
</template>
