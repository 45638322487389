<script>
import { LoaderTypes, LoaderSizes } from "@/constants/loader";

export default {
  name: "BaseLoader",
  props: {
    type: {
      type: String,
      default: LoaderTypes.PRIMARY,
      validator: (value) => Object.values(LoaderTypes).includes(value),
    },
    size: {
      type: String,
      default: LoaderSizes.MEDIUM,
      validator: (value) => Object.values(LoaderSizes).includes(value),
    },
  },
  computed: {
    loaderClasses() {
      return `${this.type} ${this.size}`;
    },
  },
};
</script>

<template lang="pug">
.loader(:class='loaderClasses')
  .loader-content.foreground
  .loader-content.background
</template>

<style lang="scss">
.loader {
  @apply rounded-full relative translate-x-0 translate-y-0;
  animation: load3 1.4s infinite linear;

  .loader-content {
    @apply absolute top-0 left-0 content-space;

    &.foreground {
      @apply w-1/2 h-1/2 rounded-tl-full;
    }
    &.background {
      @apply w-3/4 h-3/4 rounded-full;
    }
  }

  &.sm {
    @apply w-8 h-8;
  }

  &.md {
    @apply w-14 h-14;
  }

  &.primary {
    @apply bg-gradient-to-r from-primary via-transparent to-transparent;

    .foreground {
      @apply bg-primary;
    }
    .background {
      @apply bg-white;
    }
  }
  &.primary-reverse {
    @apply bg-gradient-to-r from-white via-transparent to-transparent;

    .foreground {
      @apply bg-white;
    }
    .background {
      @apply bg-primary;
    }
  }

  // Specific design must be applied
  &.ancv {
    @apply bg-gradient-to-r from-[var(--ancv-red)] via-transparent to-transparent;

    .foreground {
      background: var(--ancv-red);
    }
    .background {
      @apply bg-white;
    }
  }
}

@-webkit-keyframes load3 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
