<script>
export default {
  name: "IconLock",
  props: {
    foreground: {
      type: String,
      default: "fill-gray-900",
    },
  },
};
</script>
<template lang="pug">
.pledg-icon.icon-lock
  svg(width='14px' height='16px' viewbox='0 0 14 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink')
    title icon/lock
    desc Created with Sketch.
    defs
      path#a(d='M12,3 C9.8,3 8,4.8 8,7 L8,8 L6,8 C5.4,8 5,8.4 5,9 L5,18 C5,18.6 5.4,19 6,19 L18,19 C18.6,19 19,18.6 19,18 L19,9 C19,8.4 18.6,8 18,8 L16,8 L16,7 C16,4.8 14.2,3 12,3 Z M13,14.7 L13,16 L11,16 L11,14.7 C10.4,14.4 10,13.7 10,13 C10,11.9 10.9,11 12,11 C13.1,11 14,11.9 14,13 C14,13.7 13.6,14.4 13,14.7 Z M14,8 L10,8 L10,7 C10,5.9 10.9,5 12,5 C13.1,5 14,5.9 14,7 L14,8 Z')
    g#Symbols(stroke='none' stroke-width='1' fill='none' fill-rule='evenodd')
      g(id='icon/lock' transform='translate(-5.000000, -3.000000)')
        mask#mask-2(fill='white')
          use(xlink:href='#a')
        use(:class='foreground' fill-rule='nonzero' xlink:href='#a')

</template>
