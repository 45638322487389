<template lang="pug">
.transaction-title
  h2.dashboard-secondary-title {{ title }}
  h3(v-if='subtitle') {{ subtitle }}
</template>

<script>
export default {
  name: "TransactionTitle",
  props: {
    title: {
      type: String,
      default: undefined,
    },
    subtitle: {
      type: String,
      default: undefined,
    },
  },
};
</script>

<style scoped lang="scss">
h2.dashboard-secondary-title {
  @apply text-xl py-2 font-medium text-center sm:text-left text-gray-800;
}

h3 {
  @apply text-gray-400 text-base py-2 text-center sm:text-left;
}
</style>
