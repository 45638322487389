const _cypressEnv = (property) => {
  if (typeof Cypress === "undefined") return undefined;
  // eslint-disable-next-line
  return Cypress.env()[property];
};

const _viteEnv = (property) => {
  return typeof import.meta.env !== "undefined" ? import.meta.env[property] : undefined;
};

const getEnvValue = (property) => _viteEnv(property) || _cypressEnv(property);

const AWS_BUCKET = getEnvValue("VITE_AWS_BUCKET");
const AWS_BASE_PATH = getEnvValue("VITE_AWS_BASE_PATH");
const CDN_BASE = `https://${AWS_BUCKET}.s3.amazonaws.com/${AWS_BASE_PATH}`;

const getBoolean = (property) => {
  const value = getEnvValue(property);
  return [true, "true", "1"].includes(value);
};

const config = {
  NODE_ENV: import.meta.env.NODE_ENV,
  DEBUG: getBoolean("VITE_DEBUG"),
  APP_VERSION: import.meta.env.VITE_VERSION,
  API_BASE_URL: import.meta.env.VITE_API_BASE_URL,
  CONFIRMATION_TIMEOUT: parseInt(import.meta.env.VITE_CONFIRMATION_TIMEOUT, 10),
  ANCV_CONFIRMATION_TIMEOUT: parseInt(
    import.meta.env.VITE_ANCV_CONFIRMATION_TIMEOUT,
    10
  ),
  CHECK_STATUS_INTERVAL: parseInt(import.meta.env.VITE_CHECK_STATUS_INTERVAL, 10),
  IS_PRODUCTION: import.meta.env.NODE_ENV === "production",
  IS_TEST: import.meta.env.NODE_ENV === "test",
  CDN_BASE,
  THREATMETRIX_DOMAIN_NAME:
    import.meta.env.VITE_THREATMETRIX_DOMAIN_NAME || "h.online-metrix.net",
  THREATMETRIX_ORG_ID: import.meta.env.VITE_THREATMETRIX_ORG_ID || "6w4ciyve",
  THREATMETRIX_SCRIPT_NAME:
    import.meta.env.VITE_THREATMETRIX_SCRIPT_NAME || "plgassets.js",
  LOG_LEVEL: import.meta.env.VITE_LOG_LEVEL,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  TEST_CSS_FILENAMES: getEnvValue("TEST_CSS_FILENAMES"),
  TEST_SECRET: getEnvValue("TEST_SECRET"),
  MATOMO_ENABLED: getBoolean("VITE_MATOMO_ENABLED"),
  MATOMO_HOST: import.meta.env.VITE_MATOMO_HOST,
  MATOMO_SITE_ID: import.meta.env.VITE_MATOMO_SITE_ID,
  MATOMO_MIN_TIME_ON_PAGE_IN_MS:
    parseInt(import.meta.env.VITE_MATOMO_MIN_TIME_ON_PAGE_IN_MS, 10) || 100,
  MATOMO_DIMENSION_ID_IFRAME: import.meta.env.VITE_MATOMO_DIMENSION_ID_IFRAME,
  MATOMO_DIMENSION_ID_PAYMENT_SUCCESS: import.meta.env
    .VITE_MATOMO_DIMENSION_ID_PAYMENT_SUCCESS,
  REDIRECT_DELAY_IN_MS:
    parseInt(import.meta.env.VITE_REDIRECT_DELAY_IN_MS, 10) || 10000,
  REDIRECT_NOT_EXECUTED_DELAY_IN_MS:
    parseInt(import.meta.env.VITE_REDIRECT_NOT_EXECUTED_DELAY_IN_MS, 10) || 15000,
  UPDATE_PAYMENT_METHOD_DISABLED: getBoolean("VITE_UPDATE_PAYMENT_METHOD_DISABLED"),
  TEST_JWT_FLASK_SECRET: getEnvValue("TEST_JWT_FLASK_SECRET"),
};

export default config;
