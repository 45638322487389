<template lang="pug">
FunnelInformationMissing(
  :onSubmit='onSubmit'
  :submitting='submitting'
  :stopByCondition='stopByCondition'
  :errorHumanMessage='errorHumanMessage'
)
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { ACCOUNT, PURCHASE, MERCHANT } from "@/store/namespaces";
import Loader from "@/components/BaseLoader.vue";
import { useFunnelStep } from "@/composables/funnelStep";
import FunnelInformationMissing from "./FunnelInformationMissing.vue";
import { Logger } from "../../utils/logger";

export default {
  name: "FunnelInformationMissingPostCreation",
  components: {
    Loader,
    FunnelInformationMissing,
  },

  setup(_, ctx) {
    return useFunnelStep(ctx);
  },

  data() {
    return {
      submitting: false,
      errorHumanMessage: undefined,
    };
  },
  computed: {
    ...mapGetters(ACCOUNT, [
      "isBirthDateValid",
      "isBirthplaceValid",
      "isBirthLastNameValid",
    ]),
    ...mapGetters(PURCHASE, ["purchase", "purchaseUid"]),
    ...mapGetters(MERCHANT, [
      "isInstallmentPayment",
      "isDeferredPayment",
      "isDownPayment",
    ]),
    stopByCondition() {
      return (
        this.purchase?.is_purchase_link &&
        (this.isInstallmentPayment || this.isDeferredPayment || this.isDownPayment) &&
        (!this.isBirthDateValid ||
          !this.isBirthplaceValid ||
          !this.isBirthLastNameValid)
      );
    },
  },

  methods: {
    ...mapActions(PURCHASE, ["patchPurchaseAction"]),
    async onSubmit(formData) {
      try {
        this.submitting = true;
        this.errorHumanMessage = undefined;

        await this.patchPurchaseAction({
          purchaseUid: this.purchaseUid,
          formData,
        });
      } catch (error) {
        Logger.error({
          id: "update-purchase-failure",
          purchase_uid: this.purchase.uid,
          error,
        });
        this.errorHumanMessage = error?.errorHuman || this.$t("genericError");
        return;
      } finally {
        this.submitting = false;
      }

      if (this.stopByCondition) {
        // Despite the account update, the user still can't proceed
        Logger.info({
          id: "update-purchase-insufficient-information",
          purchase_uid: this.purchase.uid,
          data: formData,
        });
        return;
      }

      this.funnelStepDone();
    },
  },
};
</script>
