<template lang="pug">
ScreenTitle(:title="$t('title_payment_solutions')")

.flex-c-c.h-96(v-if='loading')
  Loader

.screen.payment-solutions-screen(v-else class='sm:min-w-128')
  .screen-section
    h1 {{ $t('payment_solutions_title') }}
    h1 {{ $t('payment_solutions_subtitle', { company: company.name }) }}

    template(v-if="sortedMerchants && sortedMerchants.length")
      h2.my-4 {{ $t('payment_solutions_choice_infos') }}

      .payment-solutions.max-h-96.overflow-auto.px-4
          PaymentSolutionRow(
            :merchant='merchant'
            v-for="merchant in sortedMerchants" 
            :key="merchant.uid"
            @payment-solution-selected='updateMerchantUidSelection'
            :active="merchant.uid === selectedMerchantUid"
          )
    template(v-else)
      p.px-4.my-8.text-center.leading-5.text-gray-400(v-t="'payment_solutions_no_merchants_found'")

    .screen-section.submit-section(v-if="sortedMerchants && sortedMerchants.length")
      .line_disclaimer
        span(v-html="$t('data_policy', { dataPolicyUrl: companyDataPolicyUrl })")

      ElButton(
        class="w-1/2"
        type="primary"
        size="large"
        :disabled='!canSubmit'
        @click='goToFunnelEntrypoint'
        data-cy="submit"
      )
        .grow {{ $t("submit") }}

  SofincoLogo
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import _sortBy from "lodash.sortby";
import Loader from "@/components/BaseLoader.vue";
import { COMPANY, FUNNEL, MERCHANT } from "@/store/namespaces";
import DataError from "@/models/DataError";
import { ValidityPayload } from "@/models/plugin";
import { PLUGIN_EVENTS } from "@/constants";
import { usePlugin } from "@/composables/plugin";
import { useRouter as useVueRouter } from "vue-router";
import SofincoLogo from "@/components/SofincoLogo.vue";
import ScreenTitle from "@/components/ScreenTitle.vue";
import PaymentSolutionRow from "./PaymentSolutionRow.vue";
import { ROUTE_NAMES } from "../../../constants/routes";

export default {
  name: "PaymentSolutionsList",
  components: {
    Loader,
    PaymentSolutionRow,
    SofincoLogo,
    ScreenTitle,
  },
  setup() {
    const { postMessageParent } = usePlugin();
    const { push: routerPush } = useVueRouter();

    return { postMessageParent, routerPush };
  },
  data() {
    return {
      loading: true,
      selectedMerchantUid: undefined,
    };
  },
  computed: {
    ...mapGetters(["rawCompanyUid", "rawPaymentFunnelUid"]),
    ...mapGetters(COMPANY, ["company", "companyDataPolicyUrl"]),
    ...mapGetters(MERCHANT, ["allMerchants"]),
    sortedMerchants() {
      return _sortBy(Object.values(this.allMerchants), [
        "installments_nb",
        "delay_in_days",
        "day_of_next_month",
      ]);
    },
    canSubmit() {
      return !!this.selectedMerchantUid;
    },
  },
  async created() {
    await this.fetchFunnelAction({
      paymentFunnelUid: this.rawPaymentFunnelUid,
    });
    await this.fetchCompanyAndSimulations();

    // Send result back to plugin
    const pluginPayload = new ValidityPayload(true);
    this.postMessageParent(PLUGIN_EVENTS.PLEDG_PAYMENT_SOLUTIONS_ACCESS, pluginPayload);
  },
  methods: {
    ...mapActions(["setRawMerchantUid"]),
    ...mapActions(COMPANY, ["fetchCompanySimulationsAction"]),
    ...mapActions(FUNNEL, ["fetchFunnelAction"]),
    //
    async fetchCompanyAndSimulations() {
      if (!this.rawCompanyUid) {
        throw new DataError(
          "missing_company_uid",
          this.$t("requiredPropertyMissing", { property: "companyUid" })
        );
      }
      await this.fetchCompanySimulationsAction({
        companyUid: this.rawCompanyUid,
      });
      this.loading = false;
    },
    updateMerchantUidSelection(merchantUid) {
      if (this.selectedMerchantUid === merchantUid) {
        this.selectedMerchantUid = undefined;
      } else {
        this.selectedMerchantUid = merchantUid;
      }
    },
    async goToFunnelEntrypoint() {
      if (!this.selectedMerchantUid) return;
      await this.setRawMerchantUid({ merchantUid: this.selectedMerchantUid });
      this.routerPush({ name: ROUTE_NAMES.FUNNEL_ENTRYPOINT });
    },
  },
};
</script>
