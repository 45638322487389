<script>
import _sumBy from "lodash.sumby";
import _debounce from "lodash.debounce";

import { ElMessageBox } from "element-plus/dist/index.full";
import { mapGetters, mapActions } from "vuex";
import { validateDestination } from "@/helpers/validation";
import { PLUGIN_EVENTS, PLUGIN_SETBACK_TYPES, ShareTypes } from "@/constants";

import { LOCALES } from "@/translations/locales";
import { SHARE, PURCHASE, MERCHANT } from "@/store/namespaces";
import Mail from "@/components/icons/IconMail.vue";
import { useFunnelStep } from "@/composables/funnelStep.js";
import { usePlugin } from "@/composables/plugin.js";
import { useRouter } from "@/composables/router.js";
import SofincoLogo from "@/components/SofincoLogo.vue";
import FloatingLabelField from "@/components/form/FloatingLabelField.vue";

import { getShareOfType, isLeader } from "../../helpers/shares";

const { SPLIT_DESTINATION } = PLUGIN_SETBACK_TYPES;

export default {
  name: "FunnelInformationSplit",

  components: {
    Mail,
    SofincoLogo,
    FloatingLabelField,
  },
  beforeRouteUpdate(to, from, next) {
    if (to.query.error) {
      this.errorHuman = to.query.error;
    }
    next();
  },
  setup(_, ctx) {
    const { funnelStepDone } = useFunnelStep(ctx);
    const { queryError } = useRouter();
    const { emitSetBack, postMessageParent } = usePlugin();
    return { funnelStepDone, queryError, emitSetBack, postMessageParent };
  },
  data() {
    return {
      LOCALES,
      destination: undefined,
      destinationError: undefined,
      loading: false,
      errorHuman: undefined,
      isLeader,
    };
  },

  computed: {
    ...mapGetters(SHARE, ["leaderShare"]),
    ...mapGetters(PURCHASE, ["purchase"]),
    ...mapGetters(MERCHANT, ["merchant"]),
    ...mapGetters(["rawCountryCode"]),
    leader_can_pay_alone() {
      return this.shares.length === 1 && this.merchant.leader_can_pay_alone;
    },
    shares() {
      return this.$store.getters
        .shares(this.purchaseAmountCents)
        .map((share) => ({ ...share }));
    },

    purchaseAmountCents() {
      return this.purchase?.amount_cents || 0;
    },

    totalSharesAmountCents() {
      return (_sumBy(this.shares, "amount_cents") / 100).toFixed(2);
    },
    canSubmit() {
      return (
        (this.leader_can_pay_alone || this.shares.length > 1) && !this.destinationError
      );
    },
  },

  beforeMount() {
    this.createSharesDebounced = _debounce(this.createShares, 100);
  },

  mounted() {
    if (this.queryError) {
      this.errorHuman = this.queryError;
    }
    if (!this.shares?.length) {
      this.addLeaderShareAction({});
    }
  },

  methods: {
    ...mapActions([
      "updateShare",
      "setRawShareUid",
      "removeShare",
      "addShareAction",
      "addLeaderShareAction",
    ]),
    ...mapActions("share", ["createSharesAction"]),

    handleRemoveClick(index) {
      this.removeShare({ index });
    },

    isValidDestination(inputValue) {
      if (!inputValue) return true;
      return (
        validateDestination(inputValue, this.merchant.country_code) ||
        validateDestination(inputValue, this.rawCountryCode)
      );
    },

    async handleSubmitClick(skipAloneWarning = false) {
      if (this.destination) {
        if (this.isValidDestination(this.destination)) {
          this.destinationError = this.$t("notAddedValidDestinationWarning");
        } else {
          this.destinationError = this.$t("invalidDestination");
        }
        return;
      }

      if (this.shares.length > 1 || skipAloneWarning === true) {
        this.createSharesDebounced();
        return;
      }

      // Ask for confirmation if user wants to pay alone
      this.postMessageParent(PLUGIN_EVENTS.PLEDG_SCROLL_TO_TOP);
      try {
        await ElMessageBox.confirm(this.$t("singleShareWarning"), {
          center: true,
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
          buttonSize: "small",
        });
        if (this.leader_can_pay_alone === true) {
          this.createSharesDebounced();
        } else {
          this.postMessageParent(PLUGIN_EVENTS.PLEDG_CANCEL);
        }
      } catch (e) {
        // Do nothing if cancel
      }
    },

    handleShareAmountInput(e, shareIndex) {
      if (!e.currentTarget.value.length) {
        return;
      }

      const newAmountCents = Math.round(
        parseFloat(e.currentTarget.value) * parseFloat(100)
      );
      const prevAmountCents = this.shares[shareIndex].amount_cents;

      if (Number.isNaN(Number(e.currentTarget.value))) {
        this.updateShare({
          index: shareIndex,
          amountCents: prevAmountCents,
        });
        return;
      }

      if (newAmountCents - prevAmountCents === 0) {
        return;
      }

      const leaderShare = getShareOfType(this.shares, ShareTypes.LEADER);

      const isTooMuch = newAmountCents - prevAmountCents > leaderShare.amount_cents;

      const amountCents =
        !Number.isNaN(Number(newAmountCents)) && !isTooMuch
          ? newAmountCents
          : prevAmountCents;

      this.updateShare({
        index: shareIndex,
        amountCents,
      });
    },

    async createShares() {
      this.loading = true;
      this.errorHuman = null;

      const shares_with_amount_0 = this.shares.filter(
        (share) => share.amount_cents === 0
      );

      if (shares_with_amount_0.length) {
        this.errorHuman = this.$t("splitIncorrectAmounts");
        this.loading = false;
        return;
      }

      let shares = [];

      if (this.shares.length === 1) {
        shares.push({
          ...this.shares[0],
          amount_cents: this.purchaseAmountCents,
        });
      } else {
        shares = this.shares;
      }

      try {
        await this.createSharesAction({
          purchaseUid: this.purchase.uid,
          data: { shares },
          delete_shares: true,
        });
        this.setRawShareUid({
          shareUid: this.leaderShare.uid,
        });
        this.funnelStepDone({ pushToRouterHistory: true });
      } catch (apiResponseError) {
        this.errorHuman = apiResponseError.errorHuman || this.$t("generic_error");
      } finally {
        this.loading = false;
      }
    },

    async addShare() {
      if (!this.destination) {
        return;
      }

      if (!this.isValidDestination(this.destination)) {
        this.destinationError = this.$t("invalidDestination");
        this.emitSetBack(SPLIT_DESTINATION, this.destinationError);
        return;
      }

      const allDestinations = this.shares.map((share) => {
        return share.account.email || share.account.phone_number;
      });

      if (allDestinations.indexOf(this.destination) >= 0) {
        this.destinationError = this.$t("alreadyUsedDestination");
        this.emitSetBack(SPLIT_DESTINATION, this.destinationError);
        return;
      }

      this.destinationError = null;

      const accountDestination = this.destination.includes("@")
        ? "email"
        : "phone_number";

      const share = {
        share_type: ShareTypes.PLEDGER,
        account: {
          [accountDestination]: this.destination,
        },
      };

      await this.addShareAction({ share });
      this.destination = null;
    },
  },
};
</script>

<template lang="pug">
.screen.create-shares-screen

  .screen-section
    h1(v-t="'title_whodoisharewith'")
    h2(v-t="'subtitle_addfriends'")
  .screen-section.section-error(v-if='errorHuman') {{ errorHuman }}
  
  .screen-section.section-gray
    VVForm
      form(@submit.prevent="addShare")
        .flex.flex-col
          .destination-input-wrapper
            VVField(
              :rules='isValidDestination'
              name='destination'
              v-slot='{ field, value, errors }'
              v-model="destination"
            )
              div.flex.flex-1.items-center
                Mail.mr-3.shrink-0
                FloatingLabelField(
                  field-component="el-input"
                  v-bind="field"
                  :model-value="value"
                  size="large"
                  :class='{error: errors.length}' 
                  :placeholder="$t('placeholder_friend_email_phone_number')" 
                  data-cy='destination'
                  @input='destinationError = undefined'
                )
            
              ElButton.ml-3(
                type="primary"
                :disabled='!destination'
                @click='addShare'
                data-cy='add_share_button'
              )
                .grow.flex.justify-center.text-base.px-2
                  span.pr-2 +
                  | {{ $t("add") }}
      
          .error-message(v-if='destinationError') {{ destinationError }}
  
  .screen-section.shares-list
    h3 {{ $t("attendees", shares.length, { count: shares.length }) }}
    ul
      li(v-for='(share, index) in shares' :key='share.account.email || share.account.phone_number')
        .one-share(v-if='isLeader(share)')
          .one-share-top
            .share-left-zone
              .line-one.leader.texts
                span(v-t="'me'")
            .share-right-zone
              span.currency(v-if='$i18n.locale === LOCALES.EN')
                | {{ purchase.currency }}
              input(
                type='text'
                :value='share.amount_cents / 100'
                disabled='true'
                @blur='handleShareAmountInput($event, index)'
                @input.prevent.stop='handleShareAmountInput($event, index)'
              )
              span.currency(v-if='$i18n.locale !== LOCALES.EN')
                | {{ purchase.currency }}
        
        .one-share(v-else)
          .one-share-top
            .share-left-zone
              .line-one.pledger.texts
                .close.flex-c-c.mr-2(@click='handleRemoveClick(index)') 
                  span.text-center &times;
                span {{ share.account.email || share.account.phone_number }}
            .share-right-zone
              span.currency(v-if='$i18n.locale === LOCALES.EN')
                | {{ purchase.currency }}
              input(
                type='text' 
                :value='share.amount_cents / 100' 
                @blur='handleShareAmountInput($event, index)' 
                @input.prevent.stop='handleShareAmountInput($event, index)'
              )
              span.currency(v-if='$i18n.locale !== LOCALES.EN')
                | {{ purchase.currency }}
    .computed-total
      span
        | Total :
        template(v-if='$i18n.locale === LOCALES.EN')
          | {{ purchase.currency }}
        span.value {{ totalSharesAmountCents }}
        template(v-if='$i18n.locale !== LOCALES.EN')
          | {{ purchase.currency }}
  
  .screen-section.submit-section
    ElButton(
      class="w-1/2"
      type="primary"
      :disabled='!canSubmit'
      :loading='loading'
      @click='handleSubmitClick'
      data-cy="submit"
    )
      .grow {{ $t("submit") }}

    transition(name='slide-fade')
      .pay-alone(v-if='leader_can_pay_alone')
        a(href='#'
          @click='handleSubmitClick(true)'
          v-t="'pay_alone'"
          data-cy="pay_alone"
        )

  SofincoLogo
</template>
