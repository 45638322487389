<template lang="pug">
.floating-label-field
  component(
    ref="componentRef"
    v-bind="$attrs"
    :is="fieldComponent"
    :model-value="modelValue"
    :placeholder="null"
    @focus="isFocused = true"
    @blur="isFocused = false"
    @change="handleChange"
    v-bind:class="{...this.$attrs.class, 'has-background': hasValue && !isFocused && !isDisabled && !hasError}"
  )
    slot
    template(v-if="suffix" v-slot:suffix)
      div {{ suffix }}

  label.floating-label(
    :class="{ 'is-floating': isFocused || hasValue, 'is-disabled': isDisabled, error: hasError }"
    ) {{ $attrs.placeholder }}
</template>

<script>
export default {
  name: "FloatingLabelField",
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    fieldComponent: {
      type: String,
      required: true,
    },
    unfocusOnChange: {
      type: Boolean,
      required: false,
    },
    suffix: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isFocused: false,
    };
  },
  computed: {
    isDisabled() {
      return !!this.$attrs.disabled;
    },
    hasValue() {
      return !!this.modelValue;
    },
    hasError() {
      return this.$attrs.class?.includes("error");
    },
  },
  methods: {
    handleChange() {
      if (this.unfocusOnChange && this.$refs?.componentRef?.blur) {
        this.$refs.componentRef.blur();
        this.isFocused = false;
      }
    },
  },
};
</script>
