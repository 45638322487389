import { createI18n } from "vue-i18n";
import { DATETIME_LOCALES, LOCALES } from "@/translations/locales";

function loadLocaleMessages() {
  // import.meta.glob is a new feature of ES2020 that allows to get all files matching a pattern
  // in the current directory and its subdirectories
  // eager: true option is used to load all files at the start of the application
  const locales = import.meta.glob("../translations/*.js", { eager: true });
  const messages = {};

  Object.values(LOCALES).forEach((key) => {
    const path = `../translations/${key}.js`;
    if (locales[path]) {
      messages[key] = {
        ...locales[path].default,
      };
    }
  });

  return messages;
}

const defaultDatetimeFormatOptions = {
  short: {
    year: "numeric",
    month: "short",
    day: "numeric",
  },
  long: {
    year: "numeric",
    month: "long",
    day: "numeric",
  },
  month: {
    month: "long",
  },
};

export const datetimeFormats = Object.values(DATETIME_LOCALES).reduce((acc, locale) => {
  acc[locale] = { ...defaultDatetimeFormatOptions }; // eslint-disable-line no-param-reassign
  return acc;
}, {});

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  // This default value is normally used only if the plugin is not called
  // with a 'lang' parameter and if the back is not available
  locale: LOCALES.FR,
  messages: loadLocaleMessages(),
  silentTranslationWarn: true,
  warnHtmlInMessage: "off", // disable of the Detected HTML in message (legacy API mode)
  warnHtmlMessage: false, // disable of the Detected HTML in message (composition API mode)
  datetimeFormats,
});

export default i18n;
