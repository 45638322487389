<script>
import { mapGetters } from "vuex";
import { TransactionTypes } from "@/constants";
import OrderCheckout from "@/components/screens/CheckoutComponents/OrderCheckout.vue";
import ShareCheckout from "@/components/screens/CheckoutComponents/ShareCheckout.vue";
import { ORDER, SHARE } from "@/store/namespaces";
import AppContext from "@/AppContext";
import { useFunnelStep } from "@/composables/funnelStep";
import { useThreatMetrix } from "@/composables/threatMetrix";

export default {
  name: "FunnelCheckout",
  setup(_, ctx) {
    useThreatMetrix();
    const { funnelStepDone } = useFunnelStep(ctx);
    return { funnelStepDone };
  },
  computed: {
    ...mapGetters(SHARE, ["share"]),
    ...mapGetters(ORDER, ["order"]),

    checkoutComponent() {
      if (AppContext.transactionType === TransactionTypes.ORDER) {
        return OrderCheckout;
      }
      return ShareCheckout;
    },
  },
};
</script>

<template lang="pug">
section.flex-c-c.w-full.flex-col
  component(
    v-if="share || order"
    :is="checkoutComponent"
    @stepDone="funnelStepDone"
  )
</template>
