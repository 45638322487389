import config from "@/config";
import defaultLogger from "./defaultLogger";
import { LogLevel } from "./constants";

/**
 * Define default log verbosity depending on running environment
 */
const ENV_LOG_LEVEL = {
  test: LogLevel.NONE,
  dev: LogLevel.DEBUG,
  development: LogLevel.DEBUG,
  info: LogLevel.INFO,
  prod: LogLevel.ERROR,
  production: LogLevel.ERROR,
  default: LogLevel.WARN,
};

/**
 * Override the default Logger behavior depending on verbosity
 * @param {string} logLevel the verbosity required for the current environment
 * @returns {Logger}
 */
const registerLogger = (logLevel) => {
  let Logger;

  switch (logLevel) {
    case LogLevel.DEBUG:
      Logger = {
        ...defaultLogger,
      };
      break;
    case LogLevel.INFO:
      Logger = {
        ...defaultLogger,
        debug: () => {},
      };
      break;
    case LogLevel.WARN:
      Logger = {
        ...defaultLogger,
        info: () => {},
        debug: () => {},
      };
      break;
    case LogLevel.ERROR:
      Logger = {
        ...defaultLogger,
        info: () => {},
        warn: () => {},
        debug: () => {},
      };
      break;
    case LogLevel.NONE:
      Logger = {
        debug: () => {},
        info: () => {},
        warn: () => {},
        error: () => {},
      };
      break;
    default:
      Logger = {
        ...defaultLogger,
      };
  }
  return Logger;
};

const Logger = registerLogger(
  ENV_LOG_LEVEL[config.LOG_LEVEL || import.meta.env.NODE_ENV] || ENV_LOG_LEVEL.default
);

export { Logger, registerLogger, ENV_LOG_LEVEL };
