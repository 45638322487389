<script>
import { mapGetters } from "vuex";
import ERROR_TYPES from "@/constants/errors";
import Loader from "@/components/BaseLoader.vue";
import { useFunnelStep } from "@/composables/funnelStep";
import { useError } from "@/composables/error";

import { MERCHANT, PURCHASE } from "../../store/namespaces";

/*
  All you can find in this component is an infinit loader
  This component is the first one to be mounted in case of classic funnel:
  User call front for the first time for purchase creation.

  Several possible scenarios:
  - Payment creation is possible (canCreate) : Proceed and continue
  - Payment creation is NOT possible :
    - Can we continue, ask for more information and delegate purchase creation ?
      - Yes -> skip creation and wait for more informations
      - No  -> Abort and trigger error
*/

export default {
  components: {
    Loader,
  },

  inject: ["canCreatePurchase", "handlePaymentCreation"],
  setup(_, ctx) {
    const { handleError } = useError();
    const { funnelStepDone } = useFunnelStep(ctx);
    return { handleError, funnelStepDone };
  },

  computed: {
    ...mapGetters(["started", "rawPurchaseUid"]),
    ...mapGetters(PURCHASE, ["purchase"]),
    ...mapGetters(MERCHANT, ["isSplitPayment"]),
  },

  async mounted() {
    const { result } = this.canCreatePurchase();
    if (result) {
      await this.handlePaymentCreation();
    }
    this.done();
  },

  methods: {
    done() {
      if (!this.purchase) {
        /**
         * We can't create purchase: crash and explain why
         * If SPLIT payment: there is no way to send additional informations
         * Other payment types: redirect user to FunnelInformationMissingComponent
         * */
        const { error } = this.canCreatePurchase();
        if (error?.type === ERROR_TYPES.DISABLED_MERCHANT || this.isSplitPayment) {
          this.handleError("funnel_default_error", error?.type, error?.message, {
            reportError: false,
          });
          return;
        }
      }

      this.funnelStepDone();
    },
  },
};
</script>

<template lang="pug">
.screen-section.loader-section
  Loader
</template>
