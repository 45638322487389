<script>
import { mapGetters, mapActions } from "vuex";
import ERROR_TYPES from "@/constants/errors";
import config from "@/config";
import ShareService from "@/api/shareService";
import FormFieldWrapper from "@/components/form/FormFieldWrapper.vue";
import Loader from "@/components/BaseLoader.vue";
import BaseCheck from "@/components/BaseCheck.vue";
import { useFunnelStep } from "@/composables/funnelStep";
import { useError } from "@/composables/error";
import SofincoLogo from "@/components/SofincoLogo.vue";

import { MERCHANT, PURCHASE, SHARE } from "../../store/namespaces";

export default {
  components: {
    FormFieldWrapper,
    Loader,
    BaseCheck,
    SofincoLogo,
  },
  inject: [
    "canCreatePurchase",
    "handlePaymentCreationError",
    "createPurchaseOrOrder",
    "createShares",
  ],
  setup(_, ctx) {
    const { handleError } = useError();
    const { funnelStepDone } = useFunnelStep(ctx);
    return { handleError, funnelStepDone };
  },

  data() {
    return {
      ancvBeneficiaryId: undefined,
      email: undefined,
      isLoading: false,
      ancvAmountCents: undefined,
      ancvAmount: undefined,
      ancvTransactionId: undefined,
      ancvShareAvailable: false,
      remainingAmount: undefined,
      ancvError: false,
      delayInMinutes: 30,
    };
  },

  computed: {
    ...mapGetters(["started", "rawPurchaseUid", "rawEmail"]),
    ...mapGetters(PURCHASE, ["purchase"]),
    ...mapGetters(SHARE, ["share", "ancvShare"]),
    ...mapGetters(MERCHANT, [
      "isInstallmentPayment",
      "isDeferredPayment",
      "installmentsNb",
    ]),

    installment_cta() {
      return this.$t("installment_cta", {
        installments_nb: this.installmentsNb,
      });
    },

    deferred_cta() {
      return this.$t("deferred_cta");
    },
  },

  async mounted() {
    const { result } = this.canCreatePurchase();
    if (result) {
      await this.handlePaymentCreationAncv();
    }
  },

  methods: {
    ...mapActions(["setPaymentUidAction"]),
    ...mapActions(SHARE, ["shareAncvUpdate", "appDeleteShares"]),

    async createAncvShare() {
      // it's any other payment type than split
      await this.$store.dispatch("createAncvShare", {
        purchaseUid: this.rawPurchaseUid,
        email: this.email,
        ancv_beneficiary_id: this.ancvBeneficiaryId,
      });
    },

    async handleShareCreationAncv() {
      this.isLoading = true;

      if (this.ancvShare) {
        // look to delete
        this.appDeleteShares();
      }

      this.ancvError = false;

      try {
        await this.createAncvShare(this.email);
        await this.checkStatus();
      } catch (apiResponseError) {
        const { backError } = apiResponseError;
        if (backError?.app_error === ERROR_TYPES.ANCV_ERROR) {
          this.ancvError = true;
          this.isLoading = false;
          this.displayError = backError?.info;
        } else {
          this.handlePaymentCreationError(
            apiResponseError,
            "share_ancv_creation_error"
          );
        }
      }
    },

    async handlePaymentCreationAncv() {
      let purchaseCreation;
      try {
        purchaseCreation = await this.createPurchaseOrOrder();
      } catch (apiResponseError) {
        this.handlePaymentCreationError(
          apiResponseError,
          "ancv_purchase_creation_error"
        );
        return;
      }

      this.setPaymentUidAction({
        uid: purchaseCreation.result,
      });

      this.email = purchaseCreation.result.email;
    },

    retryCheckStatus() {
      if (this.waitingStatusTime > config.ANCV_CONFIRMATION_TIMEOUT) {
        /* Processing is taking too long, abort processing. */
        this.isLoading = false;

        const errorHuman = this.$t("ancvConfirmationTimeoutError");
        /* Save error and redirect cancel / close iframe with error. */
        this.handleError(
          "ancv_confirmation_timeout",
          ERROR_TYPES.ANCV_TIMEOUT,
          errorHuman,
          {
            displayedError: {
              title: errorHuman,
            },
          }
        );
        return;
      }

      this.waitingStatusTime += config.CHECK_STATUS_INTERVAL;
      setTimeout(this.checkStatus, config.CHECK_STATUS_INTERVAL);
    },

    handleStatusFailure(apiResponseError) {
      if (apiResponseError.isInfraError) {
        /* Network error or similar. */
        this.retryCheckStatus();
        return;
      }

      this.isLoading = false;

      const { errorHuman, statusCode, errorType } = apiResponseError;

      if (statusCode !== 400) {
        this.ancvError = true;
        this.handleError(
          "ancv_get_status_failure",
          ERROR_TYPES.PAYMENT_REFUSED,
          errorHuman,
          {
            displayedError: {
              title: errorHuman,
            },
            reportError: false,
          }
        );
        return;
      }

      if (errorType === ERROR_TYPES.ANCV_ERROR) {
        this.ancvError = true;
        this.displayError = errorHuman;
        return;
      }

      // Report unexpected error
      this.handleError(
        "ancv_get_status_unknown_error",
        ERROR_TYPES.PAYMENT_REFUSED,
        "Unknown bad parameters error"
      );
    },

    async checkStatus() {
      try {
        const { data, statusCode } = await ShareService.getStatus(this.share.uid);
        if (statusCode !== 200) {
          /* Wait for another /status http call to decide. */
          this.retryCheckStatus();
          return;
        }
        this.ancvAmountCents = data.amount_cents;
        this.ancvAmount = data.amount;
        this.ancvTransactionId = data.ancv_transaction_id;
        this.remainingAmount = data.remaining_amount;

        this.shareAncvUpdate({
          share_uid: this.share.uid,
          ancv_amount_cents: this.ancvAmountCents,
          ancv_amount: this.ancvAmount,
        });

        this.isLoading = false;
        this.ancvShareAvailable = true;
      } catch (apiResponseError) {
        this.handleStatusFailure(apiResponseError);
      }
    },

    async createBnplShares() {
      try {
        await this.createShares(this.rawEmail);
      } catch (apiResponseError) {
        this.handlePaymentCreationError(apiResponseError, "shares_creation_error");
      }
      this.funnelStepDone();
    },
  },
};
</script>

<template lang="pug">
.screen.ancv-screen
  .screen-section.ancv-logo
    img(:src="'/static/images/logo-cv-connect.svg'" width='300px')
  .screen-section.ancv-title
    section {{ $t("title_pay_with_ancv") }}
  
  .screen-section.ancv-section.error(v-if='ancvError')
    .screen-section.ancv-logo
      BaseCheck(type='failure')
    section.ancv-retry
      | {{ displayError }}
  
  .screen-section(v-if='!isLoading && !ancvShareAvailable')
    section.ancv-warning
      .row
        .col.ancv-smartphone
          img(:src="'/static/images/smartphone.svg'" width='60px')
        .col-6.ancv-warning
          strong {{ $t("important") }}
          | {{ $t("ancv_app_mandatory") }}
          hr.ancv-line
  
  div(v-if='!isLoading && !ancvShareAvailable')
    section.screen-section.ancv-bold
      | {{ $t("ancv_id") }}
    .screen-section.ancv-id(style='padding-top: 0')
      FormFieldWrapper(rules='required' name='ancvBeneficiaryId' v-slot='{ invalid }' v-if='!isLoading')
        el-input(
          size="large"
          v-model='ancvBeneficiaryId' 
          placeholder=''
          :class='{ error: invalid }'
        )
  
  .screen-section.loader-section(v-if='isLoading')
    Loader(:type="LoaderTypes.ANCV")
  
  .screen-section.ancv-section(v-if='isLoading')
    section
      | {{ $t("open_ancv_app") }}
  
  .screen-section.ancv-section(v-if='ancvShareAvailable')
    .screen-section.ancv-logo
      BaseCheck(type='success')
    section
      p {{ $t("transaction_success") }}
      p.next-line {{ $t("transaction_infos_below") }}
      p {{ $t("see_you") }}
      p {{ $t("ancv_signature") }}
  
  .screen-section.ancv-section.flex.ancv-results.ancv-slim(v-if='ancvShareAvailable')
    ul
      li {{ $t("ancv_amount") }} : {{ this.ancvAmount }} €
      li.next-line
        | {{ $t("total_amount") }} : {{ this.purchase.amount }} €
      li.next-line
        | {{ $t("ancv_transaction_id") }} : {{ this.ancvTransactionId }}
      li
        | {{ $t("remaining_amount") }} : {{ this.remainingAmount }} €
        | {{ $t("excluding_fees") }}
  
  .screen-section.submit-section
    ElButton.ancv.w-full(
      v-if='!isLoading && !ancvShareAvailable'
      :disabled='!ancvBeneficiaryId'
      @click='handleShareCreationAncv'
      data-cy="submit"
    )
      .grow {{ $t("submit") }}

  .screen-section.submit-section
    ElButton.ancv.w-full(
      v-if='ancvShareAvailable'
      @click='createBnplShares'
      data-cy="submit"
    )
      .grow
        div(v-t='installment_cta' v-if='isInstallmentPayment')
        div(v-t='deferred_cta' v-if='isDeferredPayment')

  .screen-section.line_disclaimer(v-if='!ancvShareAvailable')
    a {{ $t("infos_ancv_and_bnpl") }}
  .screen-section.line_disclaimer(v-if='ancvShareAvailable')
    a {{ $t("warning_delay_bnpl", { delayInMinutes: this.delayInMinutes }) }}

  SofincoLogo
</template>
