<script>
const CHECK_TYPE = {
  success: {
    bezierPath: "M14 27l5.917 4.917L34 17",
    class: "stroke-success fill-success",
    strokeWidth: 3,
  },
  failure: {
    bezierPath: "M 12 12 L 36 36 M 12 36 L 36 12",
    class: "stroke-failure fill-failure",
    strokeWidth: 3,
  },
};

export default {
  name: "BaseCheck",

  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return Object.keys(CHECK_TYPE).includes(value);
      },
    },
  },

  computed: {
    check_params() {
      const check_params = CHECK_TYPE[this.type];
      return check_params;
    },
  },
};
</script>

<template lang="pug">
.check.flex-c-c(v-if="check_params")
  svg.pledg-check(
    xmlns="http://www.w3.org/2000/svg"
    height="100"
    width="100"
    viewBox="0 0 48 48"
    aria-hidden="true"
  )
    circle.circle(cx="24" cy="24" r="22" :class="check_params.class")
    path.tick(
      fill="none"
      stroke="white"
      :stroke-width="check_params.strokeWidth"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      :d="check_params.bezierPath"
    )
</template>

<style lang="scss" scoped>
@supports (animation: grow 0.5s cubic-bezier(0.25, 0.25, 0.25, 1) forwards) {
  .tick {
    stroke-opacity: 0;
    stroke-dasharray: 50px;
    stroke-dashoffset: 50px;
    animation: draw 0.5s cubic-bezier(0.25, 0.25, 0.25, 1) forwards;
    animation-delay: 0.6s;
  }

  .circle {
    fill-opacity: 0;
    stroke-width: 16px;
    transform-origin: center;
    transform: scale(0);
    animation: grow 1s cubic-bezier(0.25, 0.25, 0.25, 1.25) forwards;
  }
}

@keyframes grow {
  60% {
    transform: scale(0.6);
    stroke-width: 2px;
    fill-opacity: 0;
  }
  100% {
    transform: scale(0.9);
    stroke-width: 8px;
    fill-opacity: 1;
  }
}

@keyframes draw {
  0%,
  100% {
    stroke-opacity: 1;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
</style>
