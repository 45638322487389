<script>
export default {
  name: "CountDown",
  props: {
    duration: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      timer: undefined,
      startedAt: undefined,
      interval: 1000,
      counter: 1,
    };
  },
  computed: {
    time() {
      return this.duration - this.counter + 1;
    },
    circleSize() {
      // Calculate an offset according to the size duration (size of text)
      // So that the text fits in the circle.
      // Base size for 2 digits duration is 15.
      // Add 4 per new digit
      const offset = (this.duration.toString().length - 2) * 4;
      return 15 + offset;
    },
    animation() {
      return `countdown ${this.duration}s linear forwards`;
    },
  },
  mounted() {
    this.counter = 1;
    this.startedAt = Date.now();
    this.timer = setTimeout(this.tick, this.interval);
  },
  methods: {
    tick() {
      const nowTime = Date.now();
      const nextTime = this.startedAt + this.counter * this.interval;
      this.counter += 1;
      this.timer = setTimeout(this.tick, this.interval - (nowTime - nextTime));
      if (this.time === 0) {
        clearTimeout(this.timer);
      }
    },
  },
};
</script>

<template lang="pug">
div(v-if="time > 0").countdown
  div.countdown-number {{ time }}s
  svg
    circle(v-bind:style="{'animation': `${animation}` }" pathLength="110" v-bind:r="circleSize" cx="20" cy="20")
</template>
