<script>
import { mapGetters } from "vuex";
import {
  TransactionTypes,
  PLUGIN_EVENTS,
  BI_PROVIDER_LABELS,
  FRONT_URL_PARAMS,
} from "@/constants";
import ERROR_TYPES from "@/constants/errors";
import config from "@/config";
import Loader from "@/components/BaseLoader.vue";
import AppContext from "@/AppContext";
import { useFunnelStep } from "@/composables/funnelStep";
import { useError } from "@/composables/error";
import { usePlugin } from "@/composables/plugin";
import { useOperator } from "@/composables/operator";
import SofincoLogo from "@/components/SofincoLogo.vue";
import ScreenTitle from "@/components/ScreenTitle.vue";
import { sendMatomoPaymentSuccessfulDimension } from "@/plugins/matomo";

import { MERCHANT, ORDER, SHARE } from "../../store/namespaces";
import browserRedirectToUrl from "../../helpers/browserRedirectToUrl";
import ShareService from "../../api/shareService";
import OrderService from "../../api/orderService";

export default {
  components: {
    Loader,
    SofincoLogo,
    ScreenTitle,
  },
  emits: ["checkoutRequired"],
  setup(_, ctx) {
    const { handleError, handleInegibilityError } = useError();
    const { funnelStepDone } = useFunnelStep(ctx);
    const { postMessageParent } = usePlugin();
    const { operatorTeamName } = useOperator();
    return {
      handleError,
      handleInegibilityError,
      funnelStepDone,
      postMessageParent,
      operatorTeamName,
    };
  },

  data() {
    return {
      isLoading: true,
      waitingStatusTime: 0,
      errorHuman: undefined,
      isAnalysisNeeded: false,
      bankInsightUrl: undefined,
      BIProvider: undefined,
      statusTimeoutID: undefined,
    };
  },

  computed: {
    ...mapGetters(SHARE, ["share", "shareUid", "isPrimary"]),
    ...mapGetters(ORDER, ["order", "orderUid"]),
    ...mapGetters(MERCHANT, ["faqUrl", "enableCustomSuccessScreen", "merchantName"]),
    ...mapGetters([
      "rawEmbedded",
      "rawRedirectUrl",
      "rawIsSeminal",
      "rawIsAnalysisInProgress",
    ]),

    hasRedirectUrl() {
      return !!this.rawRedirectUrl;
    },

    informationMessages() {
      if (this.rawIsAnalysisInProgress) {
        return this.buildMessages("payment_analysis_progress_information", 2);
      }
      return this.buildMessages("payment_pending_information", 2);
    },
    providerLabel() {
      return BI_PROVIDER_LABELS[this.BIProvider];
    },

    module() {
      const transaction_service = {
        [TransactionTypes.PURCHASE]: {
          service: ShareService,
          targetUid: this.shareUid,
        },
        [TransactionTypes.ORDER]: { service: OrderService, targetUid: this.orderUid },
      };
      return transaction_service[AppContext.transactionType];
    },

    service() {
      return this.module.service;
    },
    uid() {
      return this.module.targetUid;
    },
  },

  mounted() {
    if (!this.share && !this.order)
      throw new Error("Share or Order is needed to call status api");

    this.checkStatus();
  },

  unmounted() {
    if (this.statusTimeoutID) {
      clearTimeout(this.statusTimeoutID);
    }
  },

  methods: {
    redirectToBankin() {
      browserRedirectToUrl(this.bankInsightUrl);
    },
    buildMessages(prefix, numberOfMessages) {
      const messages = [];
      for (let idx = 0; idx < numberOfMessages; idx += 1) {
        messages.push(`${prefix}_${idx + 1}`);
      }
      return messages;
    },
    buildEndOfFunnelSuccessUrl(data) {
      const url = new URL(this.rawRedirectUrl);
      url.searchParams.append(FRONT_URL_PARAMS.PLEDG_SUCCESS, JSON.stringify(data));
      return url.href;
    },
    endFunnelSuccess(data) {
      if (this.hasRedirectUrl) {
        const endOfFunnelUrl = this.buildEndOfFunnelSuccessUrl(data);
        browserRedirectToUrl(endOfFunnelUrl);
      } else if (this.rawEmbedded) {
        this.postMessageParent(PLUGIN_EVENTS.PLEDG_SUCCESS, data);
      } else {
        throw new Error("Unreachable");
      }
    },

    retryCheckStatus() {
      if (this.waitingStatusTime > config.CONFIRMATION_TIMEOUT) {
        /* Processing is taking too long, abort processing. */
        this.isLoading = false;
        this.errorHuman = this.$t("Confirmation3DSTimeoutError");

        /* Save error and redirect cancel / close iframe with error. */
        this.handleError(
          "processing_timeout",
          ERROR_TYPES._3DS_CONFIRMATION_TIMEOUT,
          this.errorHuman,
          { reportError: true }
        );
        return;
      }

      this.waitingStatusTime += config.CHECK_STATUS_INTERVAL;
      this.statusTimeoutID = setTimeout(this.checkStatus, config.CHECK_STATUS_INTERVAL);
    },

    handleStatusFailure(apiResponseError) {
      if (apiResponseError.isInfraError) {
        /* Network error or similar. */
        this.retryCheckStatus();
        return;
      }

      this.isLoading = false;

      const { statusCode, errorHuman, backError } = apiResponseError;
      this.errorHuman = errorHuman;

      if (statusCode !== 400) {
        this.handleError(
          "processing_get_status_failure",
          ERROR_TYPES.PAYMENT_REFUSED,
          this.errorHuman,
          {
            reportError: false,
          }
        );
        return;
      }

      if (backError?.app_error === ERROR_TYPES.REFUSED_PAYMENT) {
        this.handleError(
          "processing_refused_payment",
          ERROR_TYPES.PAYMENT_REFUSED,
          this.errorHuman,
          {
            reportError: false,
          }
        );
        return;
      }
      if (backError?.app_error === ERROR_TYPES.TRANSACTION_TIMEOUT) {
        this.handleError(
          "transaction_timeout",
          ERROR_TYPES.TRANSACTION_TIMEOUT,
          this.errorHuman,
          {
            reportError: false,
          }
        );
        return;
      }
      if (backError?.app_error === ERROR_TYPES.NOT_ELIGIBLE) {
        this.errorHuman = this.$t("errorHappened");
        this.handleInegibilityError("processing_not_eligible", backError?.app_error, {
          reportError: false,
        });
        return;
      }
      if (
        backError?.app_error === "refused_card" ||
        backError?.app_error === "3d_not_supported"
      ) {
        /* Let the customer try another card. */
        this.$emit("checkoutRequired", {
          error: this.errorHuman,
        });
        return;
      }
      // Report unexpected error
      this.handleError(
        "processing_unexpected_error",
        ERROR_TYPES.PAYMENT_REFUSED,
        "Unknown bad parameters error"
      );
    },

    async checkStatus() {
      try {
        const isSeminal = this.rawIsSeminal ? 1 : 0;
        const { data, statusCode } = await this.service.getStatus(this.uid, isSeminal);
        if (statusCode !== 200) {
          /* Wait for another /status http call to decide. */
          this.retryCheckStatus();
          return;
        }
        if (data.bank_insight_url) {
          this.isAnalysisNeeded = true;
          this.bankInsightUrl = data.bank_insight_url;
          this.BIProvider = data.bi_provider;
          return;
        }

        if (this.rawIsSeminal) {
          if (!this.isPrimary) throw new Error("Unreachable");

          if (this.enableCustomSuccessScreen && this.rawEmbedded) {
            /* This a very specific customer workflow, we display the success screen and
             * ends the Funnel workflow by triggering a success event.
             */
            this.isLoading = false;
            this.funnelStepDone();
          }

          /* handle payment completion. */
          sendMatomoPaymentSuccessfulDimension(true);
          this.endFunnelSuccess(data);
          return;
        }
        sendMatomoPaymentSuccessfulDimension(true);
        this.isLoading = false;
        this.funnelStepDone();
      } catch (apiResponseError) {
        this.handleStatusFailure(apiResponseError);
      }
    },
  },
};
</script>

<template lang="pug">
ScreenTitle(:title="$t('title_open_banking', { operatorTeamName })")

.screen.confirmation-screen.flex.flex-col.justify-around.items-center
  .screen-section.flex(style='padding-top: 30px')
    h1(v-if='errorHuman' v-t="'payment_cancelled'")
    h1(v-else-if='isAnalysisNeeded' v-t="'payment_analysis_needed'")
    h1(v-else-if='rawIsAnalysisInProgress' v-t="'payment_analysis_in_progress'")
    h1(v-else v-t="'payment_pending'")

  .screen-section.section-error.text-center(v-if='errorHuman')
    p(v-html='errorHuman')

  .screen-section.loader-section.flex(v-if='!isAnalysisNeeded')
    Loader(v-if='isLoading')

  .screen-section.flex.flex-col(v-if='!errorHuman')
    div(v-if='isAnalysisNeeded')
      .info-title.flex.flex-col
        span.px-4.pt-4.text-sm.leading-4.text-dark.text-justify(v-t="'payment_analysis_information_1'")
        span.px-4.pb-4.text-sm.leading-4.text-dark.text-justify(v-t="{ path: 'payment_analysis_information_2', args: { providerLabel } }")

      p.text-sm.p-4.leading-4.text-dark.text-justify(v-t="{ path: 'payment_analysis_information_3', args: { operatorTeamName } }")

    div(v-else)
      p.text-center.p-4.text-sm.leading-4.text-dark(v-t='informationMessage' v-for='informationMessage in informationMessages' :key='informationMessage')

  .screen-section.flex-c-c.flex-col(v-if='isAnalysisNeeded')
    .grid
      ElButton.my-4.w-full(
        @click='redirectToBankin'
        type="primary"
      )
        .grow.text-sm(v-t="'submit'")

      a.my-4.no-underline(:href='faqUrl' target='_blank')
        ElButton.w-full(type="info")
          .grow.text-sm(v-t="'see_faq'")

    p.text-sm.p-4.leading-4.text-dark.text-justify(v-t="{ path: 'payment_analysis_information_4', args: {merchantName} }")

    .logo-section.flex
      img.logo.flex(v-for="logo in ['rgpd', 'acpr', 'dsp2']" :src='`/static/images/${logo}.png`' :key='logo')

  SofincoLogo
</template>
