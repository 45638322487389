<script>
export default {
  name: "IconCircleInfo",
};
</script>
<template lang="pug">
.pledg-icon.icon-circle-info
  svg.svg-icon(viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg")
    path(d="M24.0179 18.1312C23.4206 18.1312 22.9256 17.952 22.5331 17.5936C22.1576 17.2352 21.9699 16.7829 21.9699 16.2368C21.9699 15.6907 22.1576 15.2469 22.5331 14.9056C22.9256 14.5472 23.4206 14.368 24.0179 14.368C24.6152 14.368 25.1016 14.5472 25.4771 14.9056C25.8696 15.2469 26.0659 15.6907 26.0659 16.2368C26.0659 16.7829 25.8696 17.2352 25.4771 17.5936C25.1016 17.952 24.6152 18.1312 24.0179 18.1312ZM23.3795 32.8C22.8272 32.8 22.3795 32.3523 22.3795 31.8V21.1024C22.3795 20.5501 22.8272 20.1024 23.3795 20.1024H24.6563C25.2086 20.1024 25.6563 20.5501 25.6563 21.1024V31.8C25.6563 32.3523 25.2086 32.8 24.6563 32.8H23.3795Z" fill="white")
    path(d="M24 46C36.1503 46 46 36.1503 46 24C46 11.8497 36.1503 2 24 2C11.8497 2 2 11.8497 2 24C2 36.1503 11.8497 46 24 46Z" stroke="white" stroke-width="4")

</template>
