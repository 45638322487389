<template lang="pug">
FunnelInformationMissing(
  :onSubmit='onSubmit'
  :submitting='submitting'
  :stopByCondition='stopByCondition'
)
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { Logger } from "@/utils/logger";

import { PURCHASE } from "@/store/namespaces";
import Loader from "@/components/BaseLoader.vue";
import { useFunnelStep } from "@/composables/funnelStep";
import FunnelInformationMissing from "./FunnelInformationMissing.vue";

export default {
  name: "FunnelInformationMissingPreCreation",
  components: {
    Loader,
    FunnelInformationMissing,
  },

  inject: ["handlePaymentCreation"],
  setup(_, ctx) {
    return useFunnelStep(ctx);
  },

  data() {
    return {
      submitting: false,
    };
  },

  computed: {
    ...mapGetters(["validateFunctionalFields"]),
    ...mapGetters(PURCHASE, ["purchase", "purchaseUid"]),
    stopByCondition() {
      return !this.purchase;
    },
  },

  methods: {
    ...mapActions([
      "setAddress",
      "setPhoneNumber",
      "setBirthDate",
      "setBirthCity",
      "setBirthLastName",
      "setBirthZipcode",
      "setBirthCountry",
      "setCivility",
      "setNationality",
    ]),
    async onSubmit(formData) {
      const {
        phoneNumber,
        address,
        birthCity,
        birthZipcode,
        birthCountry,
        birthLastName,
        birthDate,
        civility,
        nationality,
      } = formData;
      this.setPhoneNumber({ phoneNumber });
      this.setAddress({ address });
      this.setBirthCity({ birthCity });
      this.setBirthZipcode({ birthZipcode });
      this.setBirthCountry({ birthCountry });
      this.setBirthLastName({ birthLastName });
      this.setBirthDate({ birthDate });
      this.setCivility({ civility });
      this.setNationality({ nationality });

      // Error may be returned during validation
      const validationError = this.validateFunctionalFields;
      if (validationError) {
        Logger.error({ id: "funnel-info-validation-errors", validationError });
        return;
      }

      this.submitting = true;

      // There is no second chance after submit
      await this.handlePaymentCreation();
      this.funnelStepDone();
    },
  },
};
</script>
