<script>
export default {
  name: "IconWarning",
};
</script>
<template lang="pug">
.pledg-icon.icon-warning
  svg.svg-icon(width='20px' height='20px' viewBox="0 0 64 512")
    path(fill="currentColor" d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V320c0 17.7 14.3 32 32 32s32-14.3 32-32V64zM32 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z")

</template>
