<script>
export default {
  props: {},
};
</script>
<template lang="pug">
.pledg-icon.icon-hand
  svg(width='139' height='89' viewbox='0 0 139 89' xmlns='http://www.w3.org/2000/svg')
    g(fill='none' fill-rule='evenodd')
      path(d='M89.104 88.592a1.394 1.394 0 0 1-1.395-1.395v-7.388c0-.295.092-.582.266-.821a25.16 25.16 0 0 0 4.834-14.879V42.87a1.395 1.395 0 1 1 2.791 0v21.24c0 5.82-1.761 11.394-5.1 16.146v6.942c0 .771-.624 1.395-1.396 1.395M78.246 28.752a3.928 3.928 0 0 0-3.923 3.924v12.411a3.928 3.928 0 0 0 3.923 3.924 3.93 3.93 0 0 0 3.925-3.924V32.676a3.93 3.93 0 0 0-3.925-3.924m0 23.05a6.724 6.724 0 0 1-6.715-6.715V32.676a6.723 6.723 0 0 1 6.715-6.715 6.724 6.724 0 0 1 6.717 6.715v12.411c0 3.702-3.014 6.715-6.717 6.715' class="fill-gray-900")
      path(d='M88.885 32.08a3.928 3.928 0 0 0-3.923 3.923v10.192a3.93 3.93 0 0 0 3.923 3.925 3.93 3.93 0 0 0 3.925-3.925V36.003a3.93 3.93 0 0 0-3.925-3.923m0 20.832a6.724 6.724 0 0 1-6.715-6.717V36.003a6.723 6.723 0 0 1 6.715-6.715 6.724 6.724 0 0 1 6.717 6.715v10.192a6.724 6.724 0 0 1-6.717 6.717m-27.763 35.68a1.395 1.395 0 0 1-1.396-1.395v-6.73l-10.493-9.382a18.23 18.23 0 0 1-6.07-13.572V44.905c0-4.826 3.806-8.75 8.487-8.75h10.64c2.724 0 4.94 2.276 4.94 5.072 0 1.853-.81 4.52-4.666 6.704-2.366 1.34-5.148 2.047-8.047 2.047H51.65a1.395 1.395 0 0 1-1.395-1.396c0-.771.624-1.395 1.395-1.395h2.867c2.418 0 4.724-.582 6.672-1.685 2.157-1.223 3.25-2.66 3.25-4.275 0-1.258-.964-2.281-2.15-2.281H51.65c-3.14 0-5.696 2.673-5.696 5.959v12.608a15.44 15.44 0 0 0 5.14 11.492l10.958 9.796c.297.265.466.643.466 1.04v7.356c0 .771-.624 1.395-1.396 1.395' class="fill-gray-900")
      path(d='M76.473 72.635a1.394 1.394 0 0 1-1.396-1.396c0-7.053-5.736-12.79-12.788-12.79h-5.32a1.394 1.394 0 0 1-1.396-1.395v-8.471a1.395 1.395 0 1 1 2.791 0v7.075h3.925c8.591 0 15.58 6.99 15.58 15.581 0 .772-.624 1.396-1.396 1.396M52.728 38.946a1.393 1.393 0 0 1-1.367-1.13L45.557 8.053a6.603 6.603 0 0 1 1.387-5.473A6.6 6.6 0 0 1 52.053.17a6.63 6.63 0 0 1 6.494 5.35l6.19 31.763a1.396 1.396 0 0 1-2.74.533l-6.19-31.76a3.832 3.832 0 0 0-3.754-3.094 3.816 3.816 0 0 0-2.954 1.393 3.815 3.815 0 0 0-.802 3.163l5.802 29.765a1.394 1.394 0 0 1-1.37 1.663' class="fill-gray-900")
      path(d='M63.175 38.946a1.396 1.396 0 0 1-1.354-1.74l8.184-32.104A6.545 6.545 0 0 1 76.35.171c2.037 0 3.922.923 5.173 2.529a6.508 6.508 0 0 1 1.174 5.638l-5.026 19.725a1.396 1.396 0 0 1-2.705-.69l5.027-19.725a3.737 3.737 0 0 0-.675-3.234 3.733 3.733 0 0 0-2.968-1.452 3.758 3.758 0 0 0-3.643 2.83l-8.182 32.103a1.396 1.396 0 0 1-1.351 1.05' class="fill-gray-900")
      path(d='M136.8 45.777h-15.833a1.395 1.395 0 1 1 0-2.791h15.834a1.395 1.395 0 1 1 0 2.792m-119.005-.001H1.962a1.395 1.395 0 1 1 0-2.791h15.834a1.395 1.395 0 1 1 0 2.792M129.258 73.92c-.238 0-.478-.06-.697-.187l-13.71-7.916a1.394 1.394 0 0 1-.511-1.907 1.394 1.394 0 0 1 1.906-.511l13.71 7.917a1.395 1.395 0 0 1-.698 2.604M23.214 25.55c-.237 0-.477-.06-.696-.186L8.805 17.447a1.396 1.396 0 0 1 1.396-2.417l13.712 7.916a1.395 1.395 0 0 1-.699 2.605m92.336-.001a1.395 1.395 0 0 1-.7-2.605l13.71-7.916a1.396 1.396 0 1 1 1.396 2.418l-13.71 7.917a1.39 1.39 0 0 1-.697.187M9.504 73.92a1.395 1.395 0 0 1-.7-2.605L22.518 63.4a1.395 1.395 0 0 1 1.907.51 1.394 1.394 0 0 1-.511 1.908L10.2 73.733a1.39 1.39 0 0 1-.697.187' class="fill-gray-900")

</template>
