<template lang="pug">
.destination-input-wrapper.flex
  VVForm(v-slot='{ handleSubmit, meta }' ref='formValidator')
    form.flex(@submit.prevent='handleSubmit(onSubmit)')
      .validation-provider.validation-provider.inline-block
        VVField(
          rules='required'
          name='destination'
          v-slot='{ field, value, errors }'
          v-model='destination' 
        )
          el-input(
            v-bind="field"
            :model-value="value"
            :class="{ error: errors.length || error, 'w-32 sm:w-60': true }" 
            :title="error ? $t('error_invalid_contact') : undefined"
          )
      
      button.flex-c-c.primary.resend-invitation-button.ml-4(
        :class="{disabled: !meta.valid || submitting || error, 'w-32 sm:w-48': true }" 
        type='submit'
      )
        span(v-t="'resend_message'" class='text-xs sm:text-sm')

</template>

<script>
import { validateDestination } from "@/helpers/validation";
import ShareService from "@/api/shareService";
import { Logger } from "../../../utils/logger";

export default {
  name: "ResendInvitationForm",
  props: {
    defaultDestination: {
      type: String,
      default: undefined,
    },
    countryCode: {
      type: String,
      default: undefined,
    },
    shareUid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      destination: undefined,
      error: false,
      submitting: false,
    };
  },
  watch: {
    destination() {
      if (this.error) this.error = false;
    },
  },
  created() {
    this.destination = this.defaultDestination;
    this.validateForm();
  },
  methods: {
    validateForm() {
      this.error = false;

      if (!validateDestination(this.destination, this.countryCode)) {
        this.error = true;
      }
    },
    async onSubmit() {
      this.validateForm();
      if (this.error) return;

      try {
        this.submitting = true;
        await ShareService.resendInvitation(this.shareUid, this.destination);
      } catch (error) {
        Logger.error({ id: "resend-invitation-form-error", error });
        this.error = true;
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
form {
  align-items: center;
  justify-content: center;
}
.validation-provider {
  input {
    @apply border border-gray-200;
    @apply rounded text-gray-800;
    outline: 0;
    padding: 0 5px;
    height: 30px;
    &:focus-visible {
      @apply border border-gray-200;
    }
    &:focus-within {
      @apply border border-gray-400;
    }

    &.error {
      @apply border-failure;
    }
  }
}
</style>
