<script>
import { mapGetters } from "vuex";
import config from "@/config";
import ERROR_TYPES from "@/constants/errors";
import { getIdentification, getStatus } from "@/api/identificationService";
import BaseCheck from "@/components/BaseCheck.vue";
import Loader from "@/components/BaseLoader.vue";
import { useFunnelStep } from "@/composables/funnelStep";
import { useError } from "@/composables/error";
import SofincoLogo from "@/components/SofincoLogo.vue";

import { SHARE, MERCHANT } from "../../store/namespaces";
import browserRedirectToUrl from "../../helpers/browserRedirectToUrl";

export default {
  name: "FunnelIdentification",
  components: {
    Loader,
    BaseCheck,
    SofincoLogo,
  },

  setup(_, ctx) {
    const { handleError, handleErrorDelayed } = useError();
    const { funnelStepDone } = useFunnelStep(ctx);
    return { handleError, handleErrorDelayed, funnelStepDone };
  },

  data() {
    return {
      waitingStatusTime: 0,
      isLoading: true,
      identificationUrl: undefined,
      errorHuman: undefined,
      verificationSuccess: false,
      verificationFailure: false,
      verificationProcessing: false,
      type: undefined,
    };
  },

  computed: {
    ...mapGetters(SHARE, ["share", "mustVerifyIdentity"]),
    ...mapGetters(MERCHANT, ["contactEmail"]),
  },

  async mounted() {
    const routeQueryStatus = this.$route.query?.status;

    if (routeQueryStatus === "processing") {
      // If status in URL and is processing we skip the identification and we check the status
      this.verificationProcessing = true;
      this.checkStatus();
      return;
    }

    if (routeQueryStatus === "aborted") {
      this.setFailure(ERROR_TYPES.PAYMENT_REFUSED, "Identification Aborted");
      return;
    }
    if (this.mustVerifyIdentity) {
      await this.initRedirection();
      return;
    }

    this.funnelStepDone();
  },

  methods: {
    setSuccess() {
      this.isLoading = false;
      this.verificationSuccess = true;
      this.type = "success";
    },

    setFailure(errorType, message) {
      this.verificationFailure = true;
      this.type = "failure";
      this.isLoading = false;
      this.handleErrorDelayed("identification_failure", errorType, message, {
        errorHuman: this.errorHuman,
      });
    },

    async initRedirection() {
      if (this.share) {
        this.identificationUrl = await this.getRedirectionUrl();
        // Timeout to let the user read the text
        setTimeout(() => {
          browserRedirectToUrl(this.identificationUrl);
        }, 2000);
      }
    },

    async getRedirectionUrl() {
      try {
        const {
          data: { success, identification_url, error },
        } = await getIdentification(this.share.uid);
        if (success) {
          return identification_url;
        }
        throw error;
      } catch (error) {
        throw error.response || error;
      }
    },

    handleStatusFailure(apiResponseError) {
      if (apiResponseError.isInfraError) {
        /* Network error or similar. */
        this.retryCheckStatus();
        return;
      }

      const { statusCode, errorHuman, errorType, backError } = apiResponseError;
      this.errorHuman = errorHuman;

      if (statusCode === 400) {
        // refused_payment is a 400 in ecard-back
        if (errorType === ERROR_TYPES.REFUSED_PAYMENT) {
          this.setFailure(ERROR_TYPES.PAYMENT_REFUSED, this.errorHuman);
          return;
        }
        this.setFailure(ERROR_TYPES.PAYMENT_REFUSED, "Unknown bad parameters error");
        return;
      }

      if (statusCode === 404 && backError?.app_error === ERROR_TYPES.ERROR_PARAMS) {
        this.setFailure(ERROR_TYPES.GENERIC_ERROR, this.errorHuman);
        return;
      }

      /* Unknown Error. */
      this.setFailure(ERROR_TYPES.PAYMENT_REFUSED, "Unknown error");
    },

    retryCheckStatus() {
      if (this.waitingStatusTime > config.CONFIRMATION_TIMEOUT) {
        /* Processing is taking too long, abort processing. */
        const errorMessageKey = "IdentificationConfirmationTimeoutError";
        this.isLoading = false;
        this.errorHuman = this.$t(errorMessageKey, { contactEmail: this.contactEmail });
        // Save error and redirect cancel / close iframe with error
        this.handleError(
          "identification_timeout",
          ERROR_TYPES.IDENTIFICATION_CONFIRMATION_TIMEOUT,
          errorMessageKey
        );
        return;
      }

      this.waitingStatusTime += config.CHECK_STATUS_INTERVAL;
      setTimeout(this.checkStatus, config.CHECK_STATUS_INTERVAL);
    },

    async checkStatus() {
      try {
        const { statusCode } = await getStatus(this.share.uid);
        if (statusCode !== 200) {
          /* Retry checkStatus and wait response.
           * Status Pending is 202 in ecard-back
           */
          this.retryCheckStatus();
          return;
        }
        this.setSuccess();
        this.funnelStepDoneDelayed(null, 4000);
      } catch (apiResponseError) {
        this.handleStatusFailure(apiResponseError);
      }
    },
  },
};
</script>

<template lang="pug">
.screen.identification-screen
  .screen-section
    h1(v-if='verificationSuccess' v-t="'identification_success'")
    h1(v-else-if='verificationFailure' v-t="'identification_failure'")
    h1(v-else-if='verificationProcessing' v-t="'identification_processing'")
    div(v-else)
      h1(v-t="'identity_check'")
      p.text-center.p-4.text-sm.text-dark(v-t="'identity_check_warning_message'")
  .screen-section.section-error(v-if='errorHuman') {{ errorHuman }}
  .screen-section.loader-section
    Loader(v-if='isLoading')
    BaseCheck(v-else :type='type')

  SofincoLogo
</template>
