<script>
import { mapGetters, mapActions } from "vuex";
import { useI18n as useVueI18n } from "vue-i18n";
import { computed } from "vue";

import FunnelFooter from "@/components/FunnelFooter.vue";
import { PLUGIN_EVENTS, TransactionTypes, OPERATOR_PAGE_TITLE } from "@/constants";
import FunnelErrorModel from "@/models/FunnelErrorModel";
import { ValidationErrors } from "@/models/ValidationErrorModel";
import { useI18n } from "@/composables/i18n";
import { useError } from "@/composables/error";
import { useRouter } from "@/composables/router";
import { usePlugin } from "@/composables/plugin";
import { useOperator } from "@/composables/operator";
import { useCloseFunnelWindow } from "@/composables/closeFunnelWindow";
import { useRoute as useVueRoute } from "vue-router";
import { ElConfigProvider } from "element-plus";
import { LOCALES_ELEMENTPLUS } from "@/translations/locales";
import { sendMatomoInIframeDimension } from "@/plugins/matomo";
import { Logger } from "./utils/logger";
import { COMPANY, MERCHANT, PURCHASE, SHARE } from "./store/namespaces";
import RouteInformations from "./models/RouteInformations";
import ERROR_TYPES from "./constants/errors";
import AppContext from "./AppContext";

const { PLEDG_IS_VALID } = PLUGIN_EVENTS;

export default {
  name: "App",
  components: { FunnelFooter, ElConfigProvider },
  provide() {
    return {
      canCreatePurchase: this.canCreatePurchase,
      handlePaymentCreation: this.handlePaymentCreation,
      handlePaymentCreationError: this.handlePaymentCreationError,
      createPurchaseOrOrder: this.createPurchaseOrOrder,
      createShares: this.createShares,
    };
  },
  setup(_, ctx) {
    const { locale } = useVueI18n({ useScope: "global" });

    const { changeLocale } = useI18n(ctx);
    const { handleError } = useError();
    const route = useVueRoute();
    const { isCACFOperator, operatorTeamName } = useOperator();
    const {
      isPaymentMethodUpdateRoute,
      isDashboardBuyerRoute,
      isPaymentSolutionsRoute,
      isAccessErrorRoute,
      goBackToLastRoute,
      goToNextStep,
      goToCheckoutStep,
    } = useRouter();
    const { postMessageParent } = usePlugin();
    const { closeFunnelWindow } = useCloseFunnelWindow();

    const elementPlusLocale = computed(() => {
      return LOCALES_ELEMENTPLUS[locale.value];
    });

    return {
      // Error
      changeLocale,
      handleError,

      // Route
      route,
      isPaymentMethodUpdateRoute,
      isDashboardBuyerRoute,
      isPaymentSolutionsRoute,
      isAccessErrorRoute,
      goBackToLastRoute,
      goToNextStep,
      goToCheckoutStep,

      // Plugin
      postMessageParent,

      // Close funnel
      closeFunnelWindow,

      // i18n
      elementPlusLocale,

      // Operator
      isCACFOperator,
      operatorTeamName,
    };
  },

  data() {
    return {
      showCloseButton: undefined,
      routeInformations: undefined,
    };
  },

  computed: {
    ...mapGetters(SHARE, ["shareLanguage", "isPrimary"]),
    ...mapGetters(COMPANY, [
      "companyPictureUrl",
      "companyOperator",
      "companyFolderName",
      "companyCSSUrl",
    ]),
    ...mapGetters(MERCHANT, [
      "merchantName",
      "merchantOperator",
      "merchantFolderName",
      "merchantCSSUrl",
      "merchantPictureUrl",
      "merchantLanguage",
      "isInstallmentPayment",
      "isSplitPayment",
      "isDownPayment",
      "webSessionId",
      "isAncvPayment",
      "merchantDisabled",
    ]),
    ...mapGetters(PURCHASE, ["purchaseLanguage"]),
    ...mapGetters([
      "rawMerchantUid",
      "rawPurchaseUid",
      "rawShareUid",
      "rawEmbedded",
      "rawBalancePaymentDate",
      "started",
      "rawIsPurchaseLink",
      "rawIsSeminal",
      "rawCancelUrl",
      "rawLanguage",
      "validateTechnicalFields",
      "validateFunctionalFields",
    ]),
    layout() {
      return `layout-${this.route.meta?.layout || "blank"}`;
    },

    showRedirectionCloseButton() {
      return (
        !this.rawEmbedded &&
        this.isPrimary &&
        this.showCloseButton &&
        !this.isDashboardBuyerRoute &&
        !this.isAccessErrorRoute
      );
    },

    showHeaderBottomMessage() {
      return (
        !!this.merchantName &&
        !!this.operatorTeamName &&
        this.rawIsSeminal &&
        !this.rawEmbedded &&
        !this.isPaymentMethodUpdateRoute &&
        !this.isDashboardBuyerRoute &&
        !this.isAccessErrorRoute
      );
    },

    createHeaderBottomMessage() {
      return this.$t("redirected_from_message", {
        merchantName: this.merchantName,
        operatorTeamName: this.operatorTeamName,
      });
    },

    showSofincoLogo() {
      return this.isCACFOperator;
    },

    showCompanyPicture() {
      return this.merchantPictureUrl || this.companyPictureUrl;
    },

    headerIsNotEmpty() {
      return (
        this.showHeaderBottomMessage ||
        this.showRedirectionCloseButton ||
        this.showCompanyPicture ||
        this.showSofincoLogo
      );
    },

    showHeader() {
      // Disable header and footer for iframe usage
      const isURLCall = !this.rawEmbedded;
      return !!isURLCall && this.headerIsNotEmpty;
    },
  },

  watch: {
    merchantOperator(newOperator, oldOperator) {
      this.updateOperatorBranding(newOperator, oldOperator);
    },
    companyOperator(newOperator, oldOperator) {
      this.updateOperatorBranding(newOperator, oldOperator);
    },
    merchantCSSUrl(newCSSUrl, oldCSSUrl) {
      this.updateCSSUrl(newCSSUrl, oldCSSUrl);
    },
    companyCSSUrl(newCSSUrl, oldCSSUrl) {
      this.updateCSSUrl(newCSSUrl, oldCSSUrl);
    },
    merchantLanguage(newLanguage, oldLanguage) {
      // Dont override language if set by initial call
      if (!this.rawLanguage) {
        this.changeLocale(newLanguage, oldLanguage);
      }
    },
    shareLanguage(newLanguage, oldLanguage) {
      // Dont override language if set by initial call
      if (!this.rawLanguage) {
        this.changeLocale(newLanguage, oldLanguage);
      }
    },
    purchaseLanguage(newLanguage, oldLanguage) {
      // Dont override language if it's set by initial call unless it's different than the one set in the purchase
      if (this.rawLanguage !== newLanguage) {
        this.changeLocale(newLanguage, oldLanguage);
      }
    },
    rawLanguage(newLanguage, oldLanguage) {
      this.changeLocale(newLanguage, oldLanguage);
    },
  },
  async created() {
    this.routeInformations = new RouteInformations(this.route);
    AppContext.setTransactionType(this.routeInformations.transaction_type);
    AppContext.setSecurityToken(this.routeInformations.security_token);
    // set informations into store
    this.rawInformation({
      informations: this.routeInformations.data,
    });
    this.configureGUI();
    sendMatomoInIframeDimension(this.rawEmbedded);
  },
  mounted() {
    this.hideSplashLoader();
  },

  methods: {
    ...mapActions([
      "rawInformation",
      "setPaymentUidAction",
      "createPaymentInstanceAction",
    ]),
    configureGUI() {
      this.showCloseButton = this.routeInformations.showCloseButton;
    },

    updateOperatorBranding(newOperator, oldOperator) {
      if (!newOperator || newOperator === oldOperator) return;
      AppContext.setOperator(newOperator);
      document.title = OPERATOR_PAGE_TITLE[newOperator];
      const link = document.querySelector("link[rel~='icon']");
      if (link) {
        const folderName = this.companyFolderName || this.merchantFolderName;
        link.href = `${window.location.origin}/${folderName}/favicon.ico`;
      }
    },
    updateCSSUrl(newCSSUrl, oldCSSUrl) {
      if (!newCSSUrl || newCSSUrl === oldCSSUrl) return;

      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.type = "text/css";
      link.href = newCSSUrl;
      link.media = "all";

      // inject it into header
      const head = document.getElementsByTagName("head")[0];
      head.appendChild(link);
    },

    hideSplashLoader() {
      if (document.getElementById("app-loading-nojs")) {
        document.getElementById("app-loading-nojs").style.display = "none";
      }
    },
    canCreatePurchase() {
      let error = null;

      if (!this.rawMerchantUid) {
        error = ValidationErrors.MISSING_MERCHANT_UID;
      } else if (this.merchantDisabled) {
        error = ValidationErrors.DISABLED_MERCHANT;
      } else if (
        this.isDownPayment &&
        !this.rawShareUid &&
        !this.rawBalancePaymentDate
      ) {
        error = ValidationErrors.MISSING_BALANCE_PAYMENT_DATE;
      } else {
        error = this.validateTechnicalFields || this.validateFunctionalFields;
      }
      return {
        result: error === null,
        error,
      };
    },

    handlePaymentCreationError(apiResponseError, error_id) {
      const { errorType, backError, message, errorHuman } = apiResponseError;

      const errorPayload = {
        type: errorType,
        message: errorHuman || this.$t("genericError"),
      };

      this.postMessageParent(PLEDG_IS_VALID, { isValid: false, error: errorPayload });
      this.handleError(
        "handlePaymentCreationError",
        errorPayload.type,
        errorPayload.message,
        {
          metadata: {
            error: error_id,
            data: backError || message,
          },
          reportError: false,
        }
      );
    },

    async handlePaymentCreation() {
      let paymentCreationResponse;
      try {
        paymentCreationResponse = await this.createPurchaseOrOrder();
        Logger.info(
          `${AppContext.transactionType} ${paymentCreationResponse.result} created`
        );
      } catch (apiResponseError) {
        this.handlePaymentCreationError(
          apiResponseError,
          `${AppContext.transactionType}_creation_error`
        );
        return;
      }

      this.setPaymentUidAction({
        uid: paymentCreationResponse.result,
      });

      try {
        if (
          AppContext.transactionType === TransactionTypes.PURCHASE &&
          !this.isSplitPayment
        ) {
          await this.createShares(paymentCreationResponse.result.email);
        }
        this.postMessageParent(PLEDG_IS_VALID, { isValid: true });
      } catch (apiResponseError) {
        this.handlePaymentCreationError(apiResponseError, "shares_creation_error");
      }
    },

    async createPurchaseOrOrder() {
      const result = await this.createPaymentInstanceAction();
      this.routeInformations.save(result.result);
      return result;
    },

    async createShares(email) {
      // it's any other payment type than split
      const shareUid = await this.$store.dispatch("createShares", {
        purchaseUid: this.rawPurchaseUid,
        email,
        // don't delete ancv share when merchant is ANCV
        delete_shares: !this.isAncvPayment,
      });
      return shareUid;
    },

    cancel() {
      if (this.isPaymentSolutionsRoute || (!this.rawCancelUrl && !this.rawEmbedded)) {
        // Beware: router.go(-1) will go back to last previous page:
        this.goBackToLastRoute();
        return;
      }

      this.closeFunnelWindow(
        new FunnelErrorModel({
          type: ERROR_TYPES.ABANDONMENT,
          message: "Payment abandonned by the user",
        }),
        0
      );
    },
  },
};
</script>

<template lang="pug">
#app.font-sans(:class="{embedded: rawEmbedded, 'initial-payment': rawIsSeminal, 'dashboard': isDashboardBuyerRoute, 'sofinco': isCACFOperator}")
  header.header.bottom-border(v-if='showHeader')
    .header-top
      .merchant-picture-zone
        template(v-if='showSofincoLogo')
          img(:src="'/static/images/logo_sofinco.svg'")
          .logo-separator.mr-3(v-if='showCompanyPicture')
        img(v-if='showCompanyPicture' :src='merchantPictureUrl || companyPictureUrl')
      .pledg-iframe-close(v-if='showRedirectionCloseButton' @click='cancel')
        template(v-if="merchantName && !rawIsPurchaseLink && !!rawIsSeminal")
          span.underline.cursor-pointer.text-xs(class='hover:text-secondary') {{ $t('back_to_website', {website: merchantName}) }}
        template(v-else)
          img(:src="'/static/images/close_icon.svg'" alt="Close")
  .header-bottom-message.bottom-border.text-dark(v-if='showHeaderBottomMessage') {{ this.createHeaderBottomMessage }}
  main#content.main
    el-config-provider(:locale="elementPlusLocale")
      component(:is="layout")
        router-view(
          :key="route.path"
          @stepDone='goToNextStep'
          @checkoutRequired='goToCheckoutStep'
        )

  FunnelFooter
</template>

<style lang="scss">
@use "@/styles/main.scss" as *;
</style>
