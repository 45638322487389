<template lang="pug">
.box-container.bg-full(:class="boxContainerClass")
  .box-section.flex.items-center(v-if="purchases.length")
    component.w-16.flex-c-c.h-8(:is="currentStatusIcon" v-if="!$isMobile()")
    .flex.flex-col
      p.text-sm.font-bold.text-white {{ $t(`${paymentTypeLower}_intro`, { ...localizedCreated, merchantName }) }}
      p.text-sm.font-thin.text-white {{ $t(statusMessage, { merchantName }) }}
</template>

<script>
import { mapGetters } from "vuex";
import { MERCHANT } from "../../../store/namespaces";
import { PurchaseStates } from "../../../constants";

const StatusIcons = {
  INFO: "icon-circle-info",
  WARNING: "icon-circle-warning",
  CHECK: "icon-circle-check",
};

const BoxContainerClass = {
  DANGER: "danger",
  SUCCESS: "success",
  WARNING: "warning",
  INFO: "info",
};

export default {
  name: "StatusPanel",
  props: {
    purchases: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    localizedCreated: {
      type: Object,
      required: true,
      default() {
        return {
          date: "",
          hour: "",
          timezone_city: "",
        };
      },
    },
  },
  computed: {
    ...mapGetters(MERCHANT, ["paymentTypeLower", "isSplitPayment", "merchantName"]),
    //
    currentStatusIcon() {
      if (this.hasOverdue || this.pspSourceExpiredSoon) return StatusIcons.WARNING;
      if (this.purchasesFullyPaid) return StatusIcons.CHECK;
      return StatusIcons.INFO;
    },
    purchasesFullyPaid() {
      return this.purchases.every(
        (purchase) => purchase.state === PurchaseStates.PAYMENT_OK
      );
    },
    purchasesFullyRefunded() {
      return this.purchases.every((purchase) =>
        [PurchaseStates.CANCELLED, PurchaseStates.RELEASED].includes(purchase.state)
      );
    },
    hasOverdue() {
      return this.purchases.some((purchase) => purchase.has_overdue);
    },
    pspSourceExpiredSoon() {
      return this.purchases.some((purchase) => purchase.last_psp_source?.expired_soon);
    },
    hasPledgerSharesNotPaid() {
      return this.purchases.some((purchase) => purchase.has_pledgers_shares_not_paid);
    },
    boxContainerClass() {
      if (this.purchasesFullyPaid) return BoxContainerClass.SUCCESS;
      if (this.purchasesFullyRefunded) return BoxContainerClass.INFO;
      if (this.hasPledgerSharesNotPaid) return BoxContainerClass.SUCCESS;
      if (this.hasOverdue) return BoxContainerClass.DANGER;
      if (this.pspSourceExpiredSoon) return BoxContainerClass.WARNING;
      // on going
      return BoxContainerClass.SUCCESS;
    },
    statusMessage() {
      if (this.purchasesFullyPaid) return "dashboard_buyer_status_fully_paid";
      if (this.purchasesFullyRefunded) return "dashboard_buyer_status_total_refund";
      if (this.hasPledgerSharesNotPaid)
        return "dashboard_buyer_status_split_waiting_payment";
      if (this.hasOverdue) return "dashboard_buyer_status_overdue";
      if (this.pspSourceExpiredSoon) return "dashboard_buyer_status_psp_source_expired";
      return "dashboard_buyer_status_ongoing";
    },
  },
};
</script>
