<template lang="pug">
form#payment-form.max-w-xl.m-auto
  // Display error message to your customers here
  .screen-section.section-error(v-if="errorMessage")
    span {{ errorMessage }}
  
  Loader.mx-auto.mb-2(:size="LoaderSizes.SMALL" v-else-if='!ready')
  
  // Elements will create form elements here
  #payment-element(v-show='!shareIsSkipCard')
</template>

<script>
import { mapGetters } from "vuex";
import { loadStripe } from "@stripe/stripe-js";
import { tailwindColors } from "@/constants/tailwind";

import StripeService from "@/api/stripeService";
import { SHARE, PURCHASE, ACCOUNT, ORDER, MERCHANT } from "@/store/namespaces";
import { PLUGIN_SETBACK_TYPES, Operators } from "@/constants";
import ERROR_TYPES from "@/constants/errors";
import {
  StripeErrorCodes,
  StripeErrorTypes,
  PREFERRED_NETWORK,
} from "@/constants/stripe";
import Sentry from "@/plugins/sentry";
import Loader from "@/components/BaseLoader.vue";
import { usePlugin } from "@/composables/plugin";
import { Colors } from "@/constants/colors";
import AppContext from "@/AppContext";
import { Logger } from "../utils/logger";

const defaultStripeFonts = () => [
  {
    cssSrc: "https://fonts.googleapis.com/css?family=DM+Sans:300,400,500,600,700",
  },
];

const defaultStripeAppearance = () => ({
  theme: "stripe",
  labels: "floating",
  variables: {
    // Cannot use css var when defining stripe variables
    fontFamily: '"DM Sans", sans-serif',
    colorPrimary: Colors.pledg_primary,
    colorTextSecondary: tailwindColors.gray[400],
    colorTextPlaceholder: tailwindColors.gray[300],
  },
  rules: {
    ".Tab--selected, .Tab--selected:focus": {
      borderColor: "var(--colorPrimary)",
      boxShadow: "none",
    },
    ".Input:focus": {
      borderColor: "var(--colorPrimary)",
      boxShadow: "none",
    },
  },
});

const sofincoStripeAppearance = () => ({
  theme: "stripe",
  labels: "floating",
  variables: {
    // Cannot use css var when defining stripe variables
    fontFamily: '"Sarabun", sans-serif',
    colorPrimary: Colors.sofinco_primary,
    colorTextSecondary: tailwindColors.gray[400],
    colorTextPlaceholder: tailwindColors.gray[300],
    colorBackground: Colors.sofinco_light_blue,
    tabIconSelectedColor: Colors.sofinco_primary,
  },
  rules: {
    ".Input": {
      fontWeight: "bold",
      borderColor: "var(--colorPrimary)",
    },
    ".Input:focus": {
      borderColor: "var(--colorPrimary)",
      boxShadow: "none",
      backgroundColor: "#fff",
    },
    ".Input.Input--empty, .Input.Input--invalid": {
      backgroundColor: "#fff",
    },
    ".Input:focus.Input--invalid": {
      borderColor: "var(--colorDanger)",
    },
    ".Label": {
      color: "var(--colorPrimary)",
    },
    ".Label--floating": {
      opacity: 1,
    },
    ".Label--invalid": {
      color: "var(--colorDanger)",
    },
    ".Tab--selected, .Tab--selected:focus": {
      borderColor: "var(--colorPrimary)",
      boxShadow: "none",
    },
    ".Tab--selected, .Tab--selected:hover": {
      color: "var(--colorPrimary)",
    },
  },
});

export default {
  name: "StripeElement",
  components: { Loader },
  props: {
    targetUid: {
      type: String,
      required: true,
    },
    amountCents: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { emitSetBack } = usePlugin();
    return { emitSetBack };
  },

  data() {
    return {
      stripe: undefined,
      elements: undefined,
      errorMessage: undefined,
      ready: false,
    };
  },

  computed: {
    ...mapGetters([
      "rawIsSeminal",
      "rawFullAddressAvailable",
      "rawAddress",
      "rawPaymentMethodId",
      "rawEmbedded",
      "started",
    ]),
    ...mapGetters(SHARE, ["pspPaymentMethods", "shareIsSkipCard", "isSplit"]),
    ...mapGetters(PURCHASE, [
      "purchaseCountry",
      "purchaseIsScoringKOState",
      "fullAddressAvailable",
      "purchaseAddress",
    ]),
    ...mapGetters(ORDER, ["orderCountry", "orderIsScoringKOState"]),
    ...mapGetters(ACCOUNT, ["email", "phoneNumber"]),
    ...mapGetters(MERCHANT, ["cssVariables"]),

    isScoringKOState() {
      return this.purchaseIsScoringKOState || this.orderIsScoringKOState;
    },

    paymentFormOptions() {
      // https://stripe.com/docs/js/elements_object/create_payment_element
      const defaultPaymentOptions = {
        fields: {
          billingDetails: {
            address: {
              country: this.country ? "never" : "auto",
              line1: this.addressAvailable ? "never" : "auto",
              line2: this.addressAvailable ? "never" : "auto",
              city: this.addressAvailable ? "never" : "auto",
              state: this.addressAvailable ? "never" : "auto",
              postalCode: this.addressAvailable ? "never" : "auto",
            },
            name: "auto",
            email: this.isSplit ? "auto" : "never",
          },
        },
        defaultValues: {
          card: {
            network: [PREFERRED_NETWORK],
          },
        },
      };

      if (this.isSplit) {
        // Do not include phone in stripe expected fields by default
        defaultPaymentOptions.fields.billingDetails.phone = "auto";
      }

      return defaultPaymentOptions;
    },
    country() {
      return this.purchaseCountry || this.orderCountry;
    },

    addressAvailable() {
      return this.rawFullAddressAvailable || this.fullAddressAvailable;
    },

    address() {
      const address = { country: this.country };
      const addressData = this.purchaseAddress || this.rawAddress;
      if (addressData) {
        address.line1 = addressData.street;
        address.city = addressData.city;
        address.postal_code = addressData.zipcode;
      }

      if (this.addressAvailable) {
        address.line2 = "";
        address.state = "";
      }
      return address;
    },

    billingDetails() {
      const billingInfos = {
        address: this.address,
        email: this.email,
      };
      // Supply phone number for Split payment only (email may be empty)
      if (this.isSplit) {
        billingInfos.phone = this.phoneNumber;
      }
      return billingInfos;
    },

    stripeAppearance() {
      const customStripeAppearance =
        AppContext.getOperator() === Operators.CACF
          ? sofincoStripeAppearance()
          : defaultStripeAppearance();
      if (this.cssVariables) {
        const { primary_rgb_values, font_family } = this.cssVariables;
        if (primary_rgb_values) {
          customStripeAppearance.variables.colorPrimary = `rgb(${primary_rgb_values})`;
        }
        if (font_family) {
          customStripeAppearance.variables.fontFamily = `${font_family}, sans-serif`;
        }
      }
      return customStripeAppearance;
    },

    stripeFonts() {
      const customStripeFonts = defaultStripeFonts();
      if (this.cssVariables?.font_family_url) {
        customStripeFonts.push({ cssSrc: this.cssVariables?.font_family_url });
      }
      return customStripeFonts;
    },
  },
  watch: {
    async started(newValue) {
      /**
       * In iframe mode, we load the stripe form once the page is open
       */
      if (newValue && this.rawEmbedded && !this.stripe) {
        await this.preparePaymentElementForm();
      }
    },
  },

  async mounted() {
    /**
     * Load the stripe form if we are
     * - In redirection mode
     * - In iframe, but already open (the user may have been stopped at a previous step)
     */
    if (!this.rawEmbedded || (this.rawEmbedded && this.started)) {
      await this.preparePaymentElementForm();
    }
  },

  methods: {
    async preparePaymentElementForm() {
      if (this.shareIsSkipCard && !this.rawPaymentMethodId) {
        this.handleError("mounted-skip-card", {});
        return;
      }

      if (this.isScoringKOState) {
        this.handleError("mounted-scoring-ko", { type: ERROR_TYPES.RETRY_ERROR });
        return;
      }

      const paymentElement = await this.configureStripe();
      if (!paymentElement) return;

      // https://stripe.com/docs/js/element/events/on_change?type=paymentElement
      paymentElement.on("change", (event) => {
        Logger.info({
          id: "payment-element-change",
          type: event.value.type,
          complete: event.complete,
        });
        this.$emit("stripe_element_selected_psp_payment_method", event.value.type);
        this.$emit("stripe_element_form_complete", event.complete);
      });

      paymentElement.on("ready", () => {
        this.ready = true;
        Logger.info({ id: "payment-element-ready" });
        this.$emit("stripe_element_ready");
      });
    },
    /**
     *
     * Display the error message in the page
     *
     */
    handleError(id, error) {
      let message;
      if (error.isInfraError) {
        message = this.$t("error_infra_unavailable");
      }

      switch (error.code) {
        case StripeErrorCodes.CARD_DECLINED:
          message = `${this.$t("card_declined")} ${this.$t("test_another_card")}`;
          break;
        case StripeErrorCodes.PAYMENT_INTENT_AUTH_FAILURE:
        case StripeErrorCodes.INVALID_OWNER_NAME:
          message = error.message;
          break;
        default:
          break;
      }

      switch (error.type) {
        case StripeErrorTypes.CARD_ERROR:
        case StripeErrorTypes.VALIDATION_ERROR:
        case StripeErrorTypes.API_CONNECTION_ERROR:
          this.errorMessage = message || error.message;
          break;
        case ERROR_TYPES.RETRY_ERROR:
          this.errorMessage = this.$t(error.type);
          break;
        default: {
          // Detect main stripe errors in order to handle them
          if (!message && !error.errorHuman) {
            Sentry.info("stripe_error", {
              id,
              error_code: error.code,
              error_decline_code: error.decline_code,
              error_type: error.type,
              error_message: error.message,
              back_error: error.errorHuman,
              targetUid: this.targetUid,
            });
          }

          const default_message = this.shareIsSkipCard
            ? "payment_method_issue"
            : "not_supported_cards";

          this.errorMessage = message || error.errorHuman || this.$t(default_message);
          break;
        }
      }

      Logger.error({
        id: "stripe-element-error",
        error_id: id,
        error_type: error.type,
        error_code: error.code,
        error_message: this.errorMessage,
      });

      if (this.rawIsSeminal) {
        this.emitSetBack(PLUGIN_SETBACK_TYPES.CHECKOUT, this.errorMessage);
      }
    },
    async fetchStripeParams() {
      try {
        const stripeParams = await StripeService.fetchCheckoutParams(this.targetUid);
        return stripeParams;
      } catch (error) {
        this.handleError("fetch-stripe-params", error);
      }
      return undefined;
    },
    /**
     *
     * Configure Stripe.js
     *
     */
    async configureStripe() {
      const stripeParams = await this.fetchStripeParams();
      if (!stripeParams) return undefined;
      const stripePublicKey = stripeParams["data-key"];
      const stripeConnectedAccount = stripeParams["connected-account"] || undefined;
      const stripeOptions = {
        locale: this.$i18n.locale.substring(0, 2),
        stripeAccount: stripeConnectedAccount,
        // https://docs.stripe.com/payments/customize-payment-methods#filter-card-brands
        betas: ["blocked_card_brands_beta_2"],
      };

      this.stripe = await loadStripe(stripePublicKey, stripeOptions);

      const element_options = {
        mode: "payment",
        amount: this.amountCents,
        currency: this.currency?.toLowerCase(),
        paymentMethodCreation: "manual",
        paymentMethodTypes: this.pspPaymentMethods,
        appearance: this.stripeAppearance,
        fonts: this.stripeFonts,
        loader: "never",
        disallowedCardBrands: ["american_express", "discover_global_network"],
      };
      Logger.debug({ id: "stripe_config_element_options", element_options });
      this.elements = this.stripe.elements(element_options);

      Logger.debug({
        id: "stripe_config_payment_form_options",
        payment_form_options: this.paymentFormOptions,
      });
      const paymentElement = this.elements.create("payment", this.paymentFormOptions);
      paymentElement.mount("#payment-element");
      return paymentElement;
    },
    /**
     *
     * CREATE A PAYMENT INTENT (call to backend)
     *
     * @param {string} paymentMethodId the payment method identifier
     * @param {boolean} paymentMethodUpdate is true when the user wants to update its payment method only
     *
     */
    async createPaymentIntent(paymentMethodId, paymentMethodUpdate) {
      this.errorMessage = undefined;
      try {
        const { pspClientSecret, preferredNetwork, pspPaymentMethodId } =
          await StripeService.createPaymentIntent(this.targetUid, {
            pspPaymentMethods: this.pspPaymentMethods,
            paymentMethodId,
            paymentMethodUpdate,
          });
        Logger.info({
          id: "create-payment-intent-success",
          paymentMethodId,
          preferredNetwork,
          pspClientSecret,
        });
        return { pspClientSecret, preferredNetwork, pspPaymentMethodId };
      } catch (error) {
        this.handleError("create-payment-intent", error);
        throw error;
      }
    },
    /**
     *
     * VALIDATE FORM (Stripe.js)
     *
     */
    async validateForm() {
      this.errorMessage = undefined;
      const { error } = await this.elements.submit();
      if (error) this.handleError("validate-form", error);
      return !error;
    },
    /**
     *
     * CREATE A PAYMENT METHOD (Stripe.js)
     *
     * https://stripe.com/docs/js/payment_methods/create_payment_method
     *
     */
    async createPaymentMethod() {
      try {
        this.errorMessage = undefined;

        Logger.info({
          id: "create-payment-method",
          billing_details: this.billingDetails,
        });

        // Create the PaymentMethod using the details collected by the payment form
        const { error, paymentMethod } = await this.stripe.createPaymentMethod({
          elements: this.elements,
          params: {
            billing_details: this.billingDetails,
          },
        });
        if (error) throw error;
        return { paymentMethod };
      } catch (error) {
        this.handleError("create-payment-method-failure", error);
        return { error };
      }
    },
    /**
     *
     * CONFIRM A PAYMENT (Stripe.js)
     *
     * https://stripe.com/docs/js/payment_intents/confirm_payment
     *
     * @params {string} clientSecret from payment_intent
     * @params {string} payment_method the payment method ID
     * @params {object} payment_method_options the additional options to pass (i.e. preferred network)
     */
    async confirmPayment({ clientSecret, payment_method, payment_method_options }) {
      try {
        Logger.info({ id: "confirm-payment", payment_method, payment_method_options });

        const { error } = await this.stripe.confirmPayment({
          redirect: "if_required",
          clientSecret,
          confirmParams: {
            return_url: StripeService.returnURLAfterPaymentIntent(this.targetUid),
            payment_method,
            payment_method_options,
          },
        });

        if (error) throw error;

        Logger.info({ id: "confirm-payment-success" });
      } catch (error) {
        this.handleError("confirm-payment-failure", error);
        throw error;
      }
    },
  },
};
</script>
