<template lang="pug">
span.pl-1 {{ localizedAmount.amount }} {{ localizedAmount.currency }}
</template>

<script>
export default {
  name: "LocalizedAmount",
  props: {
    localizedAmount: {
      type: Object,
      default() {
        return { amount: "", currency: "" };
      },
    },
  },
};
</script>
