<script>
export default {
  name: "IconSourceTypeCard",
};
</script>
<template lang="pug">
.pledg-icon.icon-source-type-card
  svg(viewBox="0 0 16 16" class="fill-gray-400" xmlns="http://www.w3.org/2000/svg")
    path(fill-rule="evenodd" clip-rule="evenodd" d="M0 4a2 2 0 012-2h12a2 2 0 012 2H0zm0 2v6a2 2 0 002 2h12a2 2 0 002-2V6H0zm3 5a1 1 0 011-1h1a1 1 0 110 2H4a1 1 0 01-1-1z")
</template>
