<script>
export default {
  name: "IconSourceTypeIdeal",
};
</script>
<template lang="pug">
.pledg-icon.icon-source-type-ideal
  svg(viewbox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg' role='presentation' focusable='false')
    path(fill-rule='evenodd' clip-rule='evenodd' d='M0 2.75A2.75 2.75 0 0 1 2.75 0H10a8 8 0 1 1 0 16H2.75A2.75 2.75 0 0 1 0 13.25V2.75Z' fill='#fff')
    path(d='M4.433 7.516a1.113 1.113 0 1 0 0-2.226 1.113 1.113 0 0 0 0 2.226ZM5.273 12.004a1.683 1.683 0 0 1-1.685-1.684V9.004a.845.845 0 1 1 1.69 0v3h-.005Z' fill='#000')
    path(fill-rule='evenodd' clip-rule='evenodd' d='M1 2.75C1 1.784 1.784 1 2.75 1H10a7 7 0 1 1 0 14H2.75A1.75 1.75 0 0 1 1 13.25V2.75ZM2.75 2a.75.75 0 0 0-.75.75v10.5c0 .414.336.75.75.75H10a6 6 0 0 0 0-12H2.75Z' fill='#000')
    path(d='M6.55 4.56V12h3.238c2.94 0 4.214-1.66 4.214-4.009C14.002 5.744 12.727 4 9.788 4H7.11a.562.562 0 0 0-.561.56Z' fill='#E60073')

</template>
