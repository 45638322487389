<script>
export default {
  name: "IconMail",
  props: {},
};
</script>
<template lang="pug">
.pledg-icon.icon-mail
  svg(width='24px' height='20px' viewbox='0 0 24 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink')
    title icon/mail2
    defs
    g#Symbols(stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='square')
      g#foreground.strokes(transform='translate(0.000000, -2.000000)' stroke='black' stroke-width='2')
        g(transform='translate(1.000000, 3.000000)')
          polyline#Shape(points='18 4 11 11 4 4')
          rect#Rectangle-path(x='0' y='0' width='22' height='18')
          path#Shape(d='M6,12 L4,14')
          path#Shape(d='M16,12 L18,14')

</template>
