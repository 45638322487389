<script>
import { mapGetters } from "vuex";
import _debounce from "lodash.debounce";
import ERROR_TYPES from "@/constants/errors";
import errorParser from "@/helpers/errorParser";
import Sentry from "@/plugins/sentry";
import { PLUGIN_SETBACK_TYPES } from "@/constants";
import TwoFactorService from "@/api/twoFactorService";
import CountDown from "@/components/CountDown";
import { useFunnelStep } from "@/composables/funnelStep";
import SofincoLogo from "@/components/SofincoLogo.vue";
import { usePlugin } from "@/composables/plugin";
import ScreenTitle from "@/components/ScreenTitle.vue";
import { MERCHANT } from "../../store/namespaces";

export default {
  name: "Funnel2FA",
  components: {
    CountDown,
    SofincoLogo,
    ScreenTitle,
  },
  setup(_, ctx) {
    const { emitSetBack } = usePlugin();
    const { funnelStepDone, funnelStepDoneDelayed } = useFunnelStep(ctx);
    return { emitSetBack, funnelStepDone, funnelStepDoneDelayed };
  },
  data() {
    return {
      isCheckingToken: false,
      code: undefined,
      errorHuman: undefined,
      succeeded: false,
      sending: false,
      activateCounter: false,
      retryDelay: 10000,
    };
  },

  computed: {
    ...mapGetters([
      "rawPurchaseUid",
      "rawOrderUid",
      "rawPhoneNumber",
      "started",
      "mustVerifyPhoneNumber",
    ]),
    ...mapGetters(MERCHANT, ["merchantName"]),

    canSubmit() {
      return !this.isCheckingToken && this.code?.length > 4 && !this.succeeded;
    },

    targetUid() {
      return this.rawPurchaseUid || this.rawOrderUid;
    },
  },

  watch: {
    code(newValue) {
      if (newValue) {
        this.errorHuman = null;
      }
    },
    errorHuman(newValue) {
      if (newValue) {
        this.code = null;
      }
    },
    sending(newValue) {
      if (newValue) {
        this.$nextTick(this.focusOnCodeInput);
      }
    },
    started(newValue, oldValue) {
      if (!oldValue && newValue) {
        // if the user just opened the iframe: let's send otp token
        this.emitTokenDebounced(false);
      }
    },
  },

  beforeMount() {
    if (!this.mustVerifyPhoneNumber) {
      this.funnelStepDone();
    }

    this.emitTokenDebounced = _debounce(this.emitToken, this.retryDelay, {
      leading: true,
      trailing: false,
    });
  },
  mounted() {
    if (this.started) {
      this.emitTokenDebounced(false);
    }
  },

  methods: {
    focusOnCodeInput() {
      this.$refs.code_input.focus();
    },

    emitSetback(debug) {
      this.emitSetBack(PLUGIN_SETBACK_TYPES.OTP, debug);
    },
    isUnexpectedOTPRequestError(error) {
      return error?.errorType === ERROR_TYPES.UNEXPECTED_OTP_REQUEST;
    },
    async emitToken(manualRetry) {
      if (!this.mustVerifyPhoneNumber) {
        return;
      }

      this.errorHuman = null;
      try {
        this.sending = true;
        const result = await TwoFactorService.sendSMSToken(this.targetUid, manualRetry);

        if (!result?.data?.success) {
          throw new Error(result?.data?.error);
        }
        this.activateCounter = true;

        if (result?.data?.nextRetryDelay) {
          this.retryDelay = result.data.nextRetryDelay * 1000;
        }
      } catch (apiResponseError) {
        if (this.isUnexpectedOTPRequestError(apiResponseError)) {
          this.next();
          return;
        }
        Sentry.warn("send-sms-token-error", {
          manualRetry,
          targetUid: this.targetUid,
          mustVerifyPhoneNumber: this.mustVerifyPhoneNumber,
          ...errorParser(apiResponseError),
        });
        this.errorHuman = this.$t("code_transmission_error");
      } finally {
        setTimeout(() => {
          this.activateCounter = false;
          this.sending = false;
        }, this.retryDelay);
      }
    },
    async checkToken() {
      this.isCheckingToken = true;
      try {
        const {
          data: { success },
        } = await TwoFactorService.verifySMSToken(this.targetUid, this.code);
        if (success) {
          this.errorHuman = null;
          this.next();
        } else {
          this.errorHuman = this.$t("wrong_code");
          this.emitSetback(this.errorHuman);
        }
      } catch (apiResponseError) {
        if (this.isUnexpectedOTPRequestError(apiResponseError)) {
          this.next();
          return;
        }
        this.errorHuman = this.$t("unknown_error");
        this.emitSetback(this.errorHuman);
        throw apiResponseError;
      } finally {
        this.isCheckingToken = false;
      }
    },
    async sendAgain() {
      await this.emitTokenDebounced(true);
    },
    async verify() {
      try {
        await this.checkToken();
      } catch (error) {
        Sentry.error("check-token-error", {
          targetUid: this.targetUid,
          code: this.code,
          ...errorParser(error),
        });
      }
    },
    next() {
      this.isCheckingToken = true;
      this.succeeded = true;
      this.code = this.$t("code_valid");
      this.funnelStepDoneDelayed(null, 1000);
    },
  },
};
</script>

<template lang="pug">
ScreenTitle(:title="$t('title_otp')")

.screen.two-fa-screen
  .screen-section.otp-screen
    h1 {{ $t("phone_number_check") }}
    p
      small.subtitle
        | {{ $t("you_ll_receive_text") }}
        div.phone.phone-tooltip {{ rawPhoneNumber }}
          span.phone-tooltiptext {{ $t("phone_number_tooltip") }} {{ merchantName }}
    p
      small.subtitle {{ $t("please_type_code") }}

  div
    .screen-section
      input.code-input(
        ref='code_input' 
        :class='{ error: errorHuman, success: succeeded }' 
        type='text' 
        v-model='code' 
        :placeholder='123456' 
        maxlength="12"
      )
      p.error-label(:class='{ show: errorHuman }')
        | {{ errorHuman || "" }}
      p.code-action
        small.line_disclaimer.flex-c-c
            template(v-if="sending")
              span.mr-1 {{ $t("sending_sms") }}
              CountDown(v-if="activateCounter" :duration='retryDelay/1000')
            template(v-else)
              span.mr-1 {{ $t("did_not_receive") }}
              span.again(@click='sendAgain') {{ $t("send_again") }}   
    .screen-section
      ElButton(
        class="w-1/2"
        type="primary"
        :disabled='!canSubmit'
        :loading='isCheckingToken'
        @click='verify'
        data-cy="submit"
      )
        .grow {{ $t("verify") }}

    SofincoLogo
  </template>
