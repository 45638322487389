<template lang="pug">
.screen.success-screen
  .screen-section.grid.grid-cols-1
    h1 {{ $t("custom_success_screen.title") }}
    h1 {{ $t("custom_success_screen.subtitle") }}
  .screen-section.grid.grid-cols-1
    h2
      | {{ $t("custom_success_screen.text" )}}

</template>
<script>
export default {};
</script>
