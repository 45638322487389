<script>
import { mapGetters } from "vuex";
import FunnelAncv from "@/components/screens/FunnelAncv.vue";
import FunnelDefault from "@/components/screens/FunnelDefault.vue";
import { useFunnelStep } from "@/composables/funnelStep";
import { MERCHANT } from "../../store/namespaces";

export default {
  name: "FunnelEntrypoint",
  setup(_, ctx) {
    return useFunnelStep(ctx);
  },
  computed: {
    ...mapGetters(MERCHANT, ["isAncvPayment"]),

    entrypointComponent() {
      return this.isAncvPayment ? FunnelAncv : FunnelDefault;
    },
  },
};
</script>

<template lang="pug">
section.flex
  component(
    :is="entrypointComponent"
    @stepDone="funnelStepDone"
  )
</template>
