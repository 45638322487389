<template lang="pug">
ScreenTitle(:title="$t('title_payment_missing_info')")

VVForm.screen.payment-solutions-screen(
  ref="observer" 
  data-cy="missingInformationForm"
  v-slot='{ meta }'
)
  .screen-section
    h1.mb-1.text-center {{ $t("missing_information_title") }}
    h2.text-center.text-gray-400 {{ $t("missing_information_subtitle_payer") }}

  .screen-section
    //- Payer information
    .form-column.mt-4
      .flex.flex-1.flex-col
        VVField(
          rules='required'
          name='firstname'
          v-slot='{ field, value, errors }'
          v-model="form.firstName"
        )
          FloatingLabelField(
            field-component="el-input"
            v-bind="field"
            :model-value="value"
            size="large"
            :disabled="!!rawFirstName"
            :placeholder="$t('firstname')"
            :class='{ error: errors.length }'
            data-cy="firstname"
          )
        VVErrorMessage.vv-error(name="firstname" as="div")
      
      .flex.flex-1.flex-col
        VVField(
          rules='required' 
          name='lastname' 
          v-slot='{ field, value, errors }'
          v-model="form.lastName"
        )
          FloatingLabelField(
            field-component="el-input"
            v-bind="field"
            :model-value="value"
            size="large"
            :disabled="!!rawLastName"
            :placeholder="$t('lastname')"
            :class='{ error: errors.length }'
            data-cy="lastname"
          )
        VVErrorMessage.vv-error(name="lastname" as="div")

    .form-column
      .flex.flex-1.flex-col
        VVField(
          rules='required|email' 
          name='email' 
          v-slot='{ field, value, errors }'
          v-model="form.email"
        )
          FloatingLabelField(
            field-component="el-input"
            v-bind="field"
            :model-value="value"
            size="large"
            :disabled="!!rawEmail"
            :placeholder="$t('email')"
            :class='{ error: errors.length }'
            data-cy="email"
          )
        VVErrorMessage.vv-error(name="email" as="div")

      .flex.flex-1.flex-col
        VVField(
          rules='required|phone_number'
          name='phone'
          v-slot='{ field, value, errors }'
          v-model="form.phoneNumber"
        )
          FloatingLabelField(
            field-component="el-input"
            v-bind="field"
            :model-value="value"
            size="large"
            type="tel"
            :disabled="!!rawPhoneNumber"
            :placeholder="$t('phone_number')"
            :class='{ error: errors.length }'
            data-cy="phoneNumber"
          )
        VVErrorMessage.vv-error(name="phone" as="div")

    //- Address information
    .form-column
      .flex.flex-1.flex-col
        VVField(
          rules='required'
          name='address_street'
          v-slot='{ field, value, errors }'
          v-model="form.address.street"
        )
          FloatingLabelField(
            field-component="el-input"
            v-bind="field"
            :model-value="value"
            size="large"
            :disabled="!!(rawAddress ? rawAddress.street : undefined)"
            :placeholder="$t('address_street')"
            :class='{ error: errors.length }'
            data-cy="address_street"
          )
        VVErrorMessage.vv-error(name="address_street" as="div")

    .form-column
      .flex.flex-1.flex-col
        VVField(
          rules='required'
          name='address_zipcode'
          v-slot='{ field, value, errors }'
          v-model="form.address.zipcode"
        )
          FloatingLabelField(
            field-component="el-input"
            v-bind="field"
            :model-value="value"
            size="large"
            :disabled="!!(rawAddress ? rawAddress.zipcode : undefined)"
            :placeholder="$t('address_zipcode')"
            :class='{ error: errors.length }'
            data-cy="address_zipcode"
          )
        VVErrorMessage.vv-error(name="address_zipcode" as="div")

      .flex.flex-1.flex-col
        VVField(
          rules='required'
          name='address_city'
          v-slot='{ field, value, errors }'
          v-model="form.address.city"
        )
          FloatingLabelField(
            field-component="el-input"
            v-bind="field"
            :model-value="value"
            size="large"
            :disabled="!!(rawAddress ? rawAddress.city : undefined)"
            :placeholder="$t('address_city')"
            :class='{ error: errors.length }'
            data-cy="address_city"
          )
        VVErrorMessage.vv-error(name="address_city" as="div")

    .form-column
      VVField(
        rules='required'
        name='address_country'
        v-slot='{ errors }'
        v-model="form.address.country"
      )
        //- keep v-model on VVField AND el-select otherwise not working
        FloatingLabelField(
          field-component="el-select"
          v-model="form.address.country"
          size="large"
          :disabled="!!(rawAddress ? rawAddress.country : undefined)"
          :placeholder="$t('address_country')"
          :class='{ error: errors.length }'
          filterable
          data-cy="address_country"
        )
          el-option(
            v-for="country in countries"
            :key="country.code"
            :label="country.label"
            :value="country.code"
          )
      VVErrorMessage.vv-error(name="address_country" as="div")

  .screen-section.submit-section
    .line_disclaimer
      span(v-html="$t('data_policy', { dataPolicyUrl: companyDataPolicyUrl })")

    ElButton(
      class="w-1/2"
      type="primary"
      size="large"
      :disabled='!meta.valid'
      @click='handleSubmitClick'
      data-cy="submit"
    )
      .grow {{ $t("submit") }}

  SofincoLogo
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { COMPANY, FUNNEL } from "@/store/namespaces";
import FunnelService from "@/api/funnelService";
import COUNTRIES from "@/helpers/countryCodes";
import { useRouter as useVueRouter } from "vue-router";
import SofincoLogo from "@/components/SofincoLogo.vue";
import ScreenTitle from "@/components/ScreenTitle.vue";
import FloatingLabelField from "@/components/form/FloatingLabelField.vue";
import { ROUTE_PARAMS, ROUTE_NAMES } from "../../../constants/routes";

const { PAYMENT_FUNNEL_UID } = ROUTE_PARAMS;

export default {
  name: "PaymentSolutionMissingInformation",
  components: {
    SofincoLogo,
    FloatingLabelField,
    ScreenTitle,
  },
  setup() {
    const { replace: routerReplace } = useVueRouter();
    return { routerReplace };
  },
  data() {
    return {
      form: {
        email: undefined,
        firstName: undefined,
        lastName: undefined,
        address: {
          street: undefined,
          city: undefined,
          zipcode: undefined,
          country: undefined,
        },
        phoneNumber: undefined,
      },
    };
  },
  computed: {
    ...mapGetters(["rawPaymentFunnelUid"]),
    ...mapGetters(COMPANY, ["paymentFunnelUid", "companyDataPolicyUrl"]),
    ...mapGetters([
      "rawFirstName",
      "rawLastName",
      "rawPhoneNumber",
      "rawAddress",
      "rawEmail",
    ]),
    countries() {
      return COUNTRIES[this.$i18n.locale];
    },
  },
  async created() {
    await this.fetchFunnelAction({
      paymentFunnelUid: this.rawPaymentFunnelUid,
    });
    this.setDataFromRaw();
  },
  beforeMount() {
    this.setDataFromRaw();
  },
  async mounted() {
    const { valid } = await this.$refs.observer.validate();
    if (valid) {
      this.goToPaymentSolutionsList();
    }
    this.$refs.observer.resetForm();
  },
  methods: {
    ...mapActions({
      setFirstName: "setFirstName",
      setLastName: "setLastName",
      setPhoneNumber: "setPhoneNumber",
      setAddress: "setAddress",
      setEmail: "setEmail",
    }),
    ...mapActions(FUNNEL, ["fetchFunnelAction"]),
    goToPaymentSolutionsList() {
      this.routerReplace({
        name: ROUTE_NAMES.PAYMENT_SOLUTIONS_LIST,
        params: { [PAYMENT_FUNNEL_UID]: this.paymentFunnelUid },
      });
    },
    setDataFromRaw() {
      this.form.firstName = this.rawFirstName ?? this.form.firstName;
      this.form.lastName = this.rawLastName ?? this.form.lastName;
      this.form.phoneNumber = this.rawPhoneNumber ?? this.form.phoneNumber;
      this.form.address = this.rawAddress ? { ...this.rawAddress } : this.form.address;
      this.form.email = this.rawEmail ?? this.form.email;
    },
    async handleSubmitClick() {
      const { valid } = await this.$refs.observer.validate();
      if (!valid) return;

      const { firstName, lastName, phoneNumber, email, address } = this.form;
      this.setFirstName({ firstName });
      this.setLastName({ lastName });
      this.setPhoneNumber({ phoneNumber });
      this.setEmail({ email });
      this.setAddress({ address });

      await FunnelService.updateParams(this.paymentFunnelUid, {
        rawEmail: this.rawEmail,
        rawFirstName: this.rawFirstName,
        rawLastName: this.rawLastName,
        rawPhoneNumber: this.rawPhoneNumber,
        rawAddress: this.rawAddress,
      });

      this.goToPaymentSolutionsList();
    },
  },
};
</script>
