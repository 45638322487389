<template lang="pug">
.flex-c-c(style='height: 500px')
  Loader
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/BaseLoader.vue";
import { COMPANY, FUNNEL } from "@/store/namespaces";
import DataError from "@/models/DataError";
import { useRouter as useVueRouter } from "vue-router";
import { ROUTE_PARAMS, ROUTE_NAMES } from "../../../constants/routes";

const { PAYMENT_FUNNEL_UID } = ROUTE_PARAMS;

export default {
  name: "PaymentSolutionsPreCheck",
  components: {
    Loader,
  },
  setup() {
    const { replace: routerReplace } = useVueRouter();
    return { routerReplace };
  },
  computed: {
    ...mapGetters(["rawCompanyUid"]),
    ...mapGetters(COMPANY, ["paymentFunnelUid", "enableMissingInformationScreen"]),
  },
  async created() {
    await this.fetchCompany();
  },
  methods: {
    ...mapActions(["setRawPaymentFunnelUid"]),
    ...mapActions(COMPANY, ["fetchCompanyAction"]),
    ...mapActions(FUNNEL, ["createFunnelAction"]),
    async fetchCompany() {
      if (!this.rawCompanyUid) {
        throw new DataError(
          "missing_company_uid",
          this.$t("requiredPropertyMissing", { property: "companyUid" })
        );
      }
      await this.fetchCompanyAction({
        companyUid: this.rawCompanyUid,
      });
      await this.createFunnelAction({ targetUid: this.paymentFunnelUid });
      await this.setRawPaymentFunnelUid({ paymentFunnelUid: this.paymentFunnelUid });

      if (this.enableMissingInformationScreen) {
        this.routerReplace({
          name: ROUTE_NAMES.PAYMENT_SOLUTIONS_MISSING_INFORMATION,
          params: { [PAYMENT_FUNNEL_UID]: this.paymentFunnelUid },
        });
      } else {
        this.routerReplace({
          name: ROUTE_NAMES.PAYMENT_SOLUTIONS_LIST,
          params: { [PAYMENT_FUNNEL_UID]: this.paymentFunnelUid },
        });
      }
    },
  },
};
</script>
