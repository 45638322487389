<script>
export default {
  name: "IconCheck",
};
</script>
<template lang="pug">
.pledg-icon.icon-check
    svg.svg-icon(width='20px' height='20px' viewBox="0 0 448 512")
        path(fill="currentColor" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z")

</template>
