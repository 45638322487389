<script>
import { mapGetters } from "vuex";
import getFormattedPrice from "@/helpers/getFormattedPrice";
import { ORDER } from "@/store/namespaces";
import Loader from "@/components/BaseLoader.vue";
import CGULink from "@/components/CGULink.vue";
import Checkout from "@/components/screens/CheckoutComponents/Checkout.vue";
import { useFunnelStep } from "@/composables/funnelStep";

export default {
  name: "OrderCheckout",
  components: {
    Loader,
    CGULink,
    Checkout,
  },
  setup(_, ctx) {
    return useFunnelStep(ctx);
  },

  computed: {
    ...mapGetters(ORDER, ["amountCentsToCharge", "currency", "orderUid"]),

    order_formatted_amount() {
      return getFormattedPrice(
        this.amountCentsToCharge,
        this.currency,
        this.$i18n.locale
      );
    },
  },
};
</script>

<template lang="pug">

Checkout.screen.checkout-screen(
  @stepDone="funnelStepDone"
  :targetUid='orderUid',
  :amountCents='amountCentsToCharge'
  :currency='currency'
)
  template.screen-section(v-slot:title-header)
    .screen-section
      h1 {{ $t("amount_to_pay") }} : {{ order_formatted_amount }}

  template.screen-section(v-slot:timetable)
    .screen-section
      .text-center.text-lg.text-black
        span {{ $t('order_info_multiple_shipment') }}

</template>
