<template lang="pug">
.validation-provider.w-full
  VVField(
    v-slot='{ errors }'
    tag='div' 
    :key='name'
    :name='name'
    :rules='rules'
    :skipIfEmpty='false'
  )
    slot(:invalid='errors.length')
    .text-failure.py-2
      span.text-xs(v-if='errors.length') {{ errors[0] }}

</template>

<script>
export default {
  props: {
    rules: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
  },
};
</script>
