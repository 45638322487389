<script>
import { mapGetters, mapActions } from "vuex";
import Hand from "@/components/icons/IconHand.vue";
import { SHARE } from "@/store/namespaces.js";
import Loader from "@/components/BaseLoader.vue";
import { Logger } from "../../../utils/logger";

export default {
  components: {
    Hand,
    Loader,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["rawShareUid"]),
    ...mapGetters(SHARE, ["shareState"]),
  },
  created() {
    this.fetchShare();
  },
  methods: {
    ...mapActions(SHARE, ["fetchShareAction"]),
    async fetchShare() {
      if (!this.rawShareUid) {
        this.loading = false;
        return;
      }
      try {
        await this.fetchShareAction({
          shareUid: this.rawShareUid,
        });
      } catch (error) {
        Logger.warn({ id: "funnel-success-fetch-share", ...error });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<template lang="pug">
.screen.success-screen
  .flex-c-c.min-h-96(v-if="loading")
    Loader
  template(v-else)
    .screen-section.icon-section.flex-c-c
      Hand

    .screen-section
      h1 {{ $t("congratulations") }}
      h6 {{ $t(`${shareState == ShareStates.PREAUTHORIZATION_OK ? 'payment_method_registered' : 'payment_registered'}`) }}

</template>
