<script>
export default {
  name: "IconCircleCheck",
};
</script>
<template lang="pug">
.pledg-icon.icon-circle-check
  svg.svg-icon(viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg")
    path(d="M21.7873 32C21.1614 32 20.593 31.635 20.3325 31.0659L16.5185 22.7329C16.276 22.2031 16.6632 21.6 17.246 21.6H18.9887C19.3028 21.6 19.5879 21.7838 19.7174 22.07L22.8 28.8774L29.0689 17.2211C29.2084 16.9617 29.479 16.8 29.7735 16.8H31.476C32.0777 16.8 32.4641 17.4394 32.1842 17.9721L25.2635 31.1442C24.987 31.6704 24.4416 32 23.8471 32H21.7873Z" fill="white")
    path(d="M24 46C36.1503 46 46 36.1503 46 24C46 11.8497 36.1503 2 24 2C11.8497 2 2 11.8497 2 24C2 36.1503 11.8497 46 24 46Z" stroke="white" stroke-width="4")

</template>
