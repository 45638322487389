<template lang="pug">
component(
  :is="successComponent"
)
</template>

<script>
import { mapGetters } from "vuex";

import FunnelPaymentLinkSuccess from "@/components/screens/SuccessComponents/FunnelPaymentLinkSuccess.vue";
import FunnelCustomScreenSuccess from "@/components/screens/SuccessComponents/FunnelCustomScreenSuccess.vue";

import { MERCHANT } from "../../store/namespaces";

export default {
  computed: {
    ...mapGetters(MERCHANT, ["enableCustomSuccessScreen"]),
    successComponent() {
      if (this.enableCustomSuccessScreen) {
        return FunnelCustomScreenSuccess;
      }

      return FunnelPaymentLinkSuccess;
    },
  },
};
</script>
