<script>
import { mapGetters } from "vuex";
import { ROUTE_NAMES } from "@/constants/routes";
import config from "@/config";
import { MERCHANT } from "../store/namespaces";

export default {
  name: "CguLink",
  data() {
    return {
      ROUTE_NAMES,
    };
  },
  computed: {
    ...mapGetters(["rawPurchaseUid"]),
    ...mapGetters(MERCHANT, ["merchant"]),
    pdfLink() {
      return `${config.CDN_BASE}/${this.merchant.cgu_pdf_file}`;
    },
  },
};
</script>

<template lang="pug">
a(
  v-if='merchant.cgu_pdf_file'
  :href='pdfLink',
  target="_blank"
  @click.stop
)
  slot
</template>
