<script>
import { mapActions, mapGetters } from "vuex";
import { ROUTE_NAMES } from "@/constants/routes";
import IconWarning from "@/components/icons/IconWarning.vue";
import IconInfo from "@/components/icons/IconInfo.vue";
import IconTime from "@/components/icons/IconTime.vue";
import IconCheck from "@/components/icons/IconCheck.vue";
import { useI18n } from "@/composables/i18n";
import StateTag from "./StateTag.vue";
import { Logger } from "../../../utils/logger";
import { MERCHANT, ORDER } from "../../../store/namespaces";
import TransactionTitle from "./TransactionTitle";
import StatusPanel from "./StatusPanel.vue";

export default {
  components: {
    StateTag,
    IconWarning,
    IconInfo,
    IconTime,
    IconCheck,
    TransactionTitle,
    StatusPanel,
  },
  setup(_, ctx) {
    const { changeLocale } = useI18n(ctx);
    return { changeLocale };
  },
  data() {
    return {
      loading: true,
      ROUTE_NAMES,
    };
  },
  computed: {
    ...mapGetters(["rawOrderUid", "rawMerchantUid"]),
    ...mapGetters(MERCHANT, [
      "merchant",
      "merchantName",
      "isInstallmentPayment",
      "isDeferredPayment",
    ]),
    ...mapGetters(ORDER, ["order"]),
    purchases() {
      return this.order.purchases;
    },
    orderHasOverdue() {
      if (!this.purchases) return false;
      return this.purchases.some((purchase) => purchase.has_overdue);
    },
  },

  async created() {
    try {
      await this.fetchOrderAction({
        orderUid: this.rawOrderUid,
        isDashboardBuyer: true,
      });
      await this.fetchMerchantAction({
        merchantUid: this.order.merchant,
        pledgEnv: this.rawPledgEnv,
      });
      this.changeLocale(this.order.language);
    } catch (error) {
      Logger.error({ id: "order-overview-created-error", error });
      throw new Error("order-overview-created-error");
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions(ORDER, ["fetchOrderAction"]),
    ...mapActions(MERCHANT, ["fetchMerchantAction"]),
  },
};
</script>

<template lang="pug">
div(v-if="order")
  TransactionTitle(:title="order.title" :subtitle="order.subtitle")

  .overview-content
    StatusPanel(:purchases="order.purchases" :localizedCreated="order.localized_created")

    .box-container.mb-4(
      v-for='purchase in order.purchases'
      :key='purchase.uid'
      :class="{'border-failure': purchase.has_overdue, 'dashboard': purchase.dashboard_url}"
      data-cy='purchase-item'
      )
      .grid.grid-cols-1(class="md:grid-cols-3")
        .flex.inline-flex
          .self-center.ml-2.mr-5
            IconWarning.self-center(v-if="purchase.has_overdue")
            IconCheck.self-center(v-else-if="purchase.dashboard_url")
            IconTime.self-center(v-else)
          .self-center
            p.mb-2.text-gray-600 {{ $t("dashboard_buyer_products") }}
            h4.mb-2(v-for='product in purchase.meta_data.products')
              span - {{ product.name }}

        .self-center
          div(v-if="purchase.dashboard_url")
            a.no-underline.p-5(:href="purchase.dashboard_url")
              ElButton.my-0.mx-auto.w-fit(
                v-if="purchase.has_overdue"
                type="danger"
                size="small"
              )
                .text-sm(v-t="'proceed_payment_late'")
              ElButton.my-0.mx-auto.w-fit(
                v-else :to="purchase.dashboard_url"
                type="primary"
                size="small"
              )
                .text-sm {{ $t("dashboard_buyer_see_schedule") }}

        .text-right.self-center.amounts
          p.font-bold.text-lg.border-failure.text-failure(v-if="purchase.has_overdue") {{ $t("dashboard_buyer_overdue") }} {{ purchase.localized_overdue_amount.amount }} {{ purchase.localized_overdue_amount.currency }}
          p.font-bold {{ purchase.localized_effective_total_amount.amount }} {{ purchase.localized_effective_total_amount.currency }}

</template>
