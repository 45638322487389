<template lang="pug">
footer.footer(v-if='showFooter')
  .info-secure.flex-c-c
    IconLock.mr-2(foreground='fill-gray-400')
    span.mr-2 {{ $t('secured_payment') }}
    //- middle dot ·
    span &#183;
  
  .language.flex-c-c
    span.mx-2 {{ $t('language') }}
    el-dropdown(trigger="click" @command="changeLocale")
      span.el-dropdown-link
        | {{ LocalesLabels[currentLocale] }}
        el-icon.el-icon--right(style="top: 3px")
          arrow-down
      template(#dropdown='')
        el-dropdown-menu
          el-dropdown-item(
            v-for="label, key in LocalesLabels"
            :key="key"
            :command="key"
          ) {{ label }}

</template>

<script>
import { mapGetters } from "vuex";
import IconLock from "@/components/icons/IconLock.vue";
import { useI18n } from "@/composables/i18n";
import { useRouter } from "@/composables/router";
import { ArrowDown } from "@element-plus/icons-vue";

export default {
  name: "FunnelFooter",
  components: {
    IconLock,
    ArrowDown,
  },
  setup(_, ctx) {
    const { changeLocale } = useI18n(ctx);
    const { isDashboardBuyerRoute, isAccessErrorRoute } = useRouter();
    return {
      changeLocale,
      isDashboardBuyerRoute,
      isAccessErrorRoute,
    };
  },
  data() {
    return {
      currentLocale: undefined,
    };
  },
  computed: {
    ...mapGetters(["rawEmbedded"]),
    showFooter() {
      /**
       * Display footer only when
       * - Redirection mode
       * - Not in dashboard buyer
       */
      const isURLCall = !this.rawEmbedded;
      return !!isURLCall && !this.isDashboardBuyerRoute && !this.isAccessErrorRoute;
    },
  },
  watch: {
    "$i18n.locale": function (newValue) {
      this.currentLocale = newValue;
    },
  },
  mounted() {
    this.currentLocale = this.$i18n.locale;
  },
};
</script>
