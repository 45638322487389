<script>
import { mapGetters } from "vuex";

import PurchaseService from "@/api/purchaseService";
import { useFunnelStep } from "@/composables/funnelStep";
import { useError } from "@/composables/error";
import { useI18n } from "@/composables/i18n";
import SofincoLogo from "@/components/SofincoLogo.vue";
import ScreenTitle from "@/components/ScreenTitle.vue";
import FloatingLabelField from "@/components/form/FloatingLabelField.vue";
import ERROR_TYPES from "../../constants/errors";

const BGV_LINK = "https://www.bgvapp.com/?o=eWjRLI";

export default {
  name: "FunnelRevenue",
  components: {
    FloatingLabelField,
    SofincoLogo,
    ScreenTitle,
  },
  setup(_, ctx) {
    const { to } = useI18n(ctx);
    const { funnelStepDone } = useFunnelStep(ctx);
    const { handleInegibilityError } = useError();
    return { to, handleInegibilityError, funnelStepDone };
  },

  data() {
    return {
      loading: false,
      responseError: undefined,
      declaredRevenue: undefined,
      declaredExpenditure: undefined,
      declaredCSP: undefined,
      revenueTooltipVisibility: false,
      expenditureTooltipVisibility: false,
      showBgv: false,
      wasValidated: false,
    };
  },

  computed: {
    ...mapGetters("purchase", ["purchase", "purchaseUid"]),
    ...mapGetters("merchant", ["dataPolicyUrl"]),
    currency() {
      return this.purchase.currency_symbol;
    },
    fitRevenueRequirement() {
      if (
        this.purchase.revenue_info_is_mandatory &&
        (!this.declaredRevenue ||
          !this.declaredExpenditure ||
          this.declaredRevenue < 0 ||
          this.declaredExpenditure < 0)
      )
        return false;
      return true;
    },
    fitCspRequirement() {
      if (this.purchase.revenue_csp_is_mandatory && !this.declaredCSP) return false;
      return true;
    },
    validateRevenueFields() {
      return this.fitRevenueRequirement && this.fitCspRequirement;
    },
    professionalCategories() {
      const professional_categories = this.$tm("professional_categories");
      return professional_categories;
    },
  },

  async mounted() {
    if (
      !this.purchase.revenue_info_is_mandatory ||
      /* Reload with correct state and revenue already entered */
      this.purchase.revenue_was_collected
    ) {
      /*
        We don't need revenue information, we can move on
      */
      this.funnelStepDone();
      return;
    }
    this.BGV_LINK = BGV_LINK;
    await this.$refs.formValidator.validate();
  },

  methods: {
    async handleRevenueUpdate() {
      await PurchaseService.setRevenue(
        this.purchaseUid,
        this.declaredRevenue,
        this.declaredExpenditure,
        this.declaredCSP
      );
    },

    handleStatusFailure(apiResponseError) {
      const { backError } = apiResponseError;
      this.responseError = backError?.app_error;

      if (this.responseError === ERROR_TYPES.NOT_ELIGIBLE) {
        if (this.purchase.revenue_show_bgv_notice) {
          /* showing bgv notice with the link to bgv-crésus */
          this.showBgv = true;
          return;
        }
        /* Save error and redirect cancel / close iframe with error. */
        this.handleInegibilityError("revenue_failure", this.responseError);
      }
    },

    async handleSubmitClick() {
      this.loading = true;
      this.wasValidated = true;

      try {
        await this.handleRevenueUpdate();
        this.funnelStepDone();
      } catch (apiResponseError) {
        this.loading = false;
        this.handleStatusFailure(apiResponseError);
      }
    },

    async goBackToMerchantWithError() {
      this.loading = true;
      /* Save error and redirect cancel / close iframe with error. */
      this.handleInegibilityError("revenue_back_to_merchant", this.responseError);
    },
  },
};
</script>

<template lang="pug">
ScreenTitle(:title="$t('title_revenue_collection')")

.screen
  VVForm(ref='formValidator')
    GlobalEvents(@keyup.enter.stop='handleSubmitClick')

    .screen-section
      h1 {{ $t("title_revenue") }}
      p.mt-5.text-center {{ $t("revenue_explanation") }}

    .screen-section
      template(v-if='this.purchase.revenue_csp_is_mandatory')
        .mb-4
          VVField(
            name="declared_csp"
            v-slot='{ field, value, errors }'
            v-model="declaredCSP"
          )
            FloatingLabelField(
              field-component="el-select"
              v-model="declaredCSP"
              size="large"
              :placeholder="$t('declared_csp')"
              :class='{ error: errors.length }'
              :unfocus-on-change="true"
              filterable
              :teleported="false"
              data-cy="declared_csp"
            )
              el-option(v-for="item in professionalCategories" :value="item" :key="item" :label="item")
          VVErrorMessage.vv-error(name="declared_csp" as="div")

      .form-column
        .flex.flex-col.flex-1
          VVField(
            rules="positive"
            name='declared_revenue'
            v-slot='{ field, value, errors }'
            v-model="declaredRevenue"
          )
            FloatingLabelField(
              field-component="el-input"
              v-bind="field"
              :model-value="value"
              size="large"
              :disabled="wasValidated"
              :placeholder="$t('declared_revenue')"
              :class='{ error: errors.length }'
              @focus="revenueTooltipVisibility = true"
              @blur="revenueTooltipVisibility = false"
              :suffix="currency"
              type="number"
              data-cy="declared_revenue"
            )
          VVErrorMessage.vv-error(name="declared_revenue" as="div")
          .h-4: span.tooltiptext(v-show="revenueTooltipVisibility") {{ $t("tooltip_revenue") }}

        .flex.flex-col.flex-1
          VVField(
            rules="positive"
            name="declared_expenditure"
            v-slot='{ field, value, errors }'
            v-model="declaredExpenditure"
          )
            FloatingLabelField(
              field-component="el-input"
              v-bind="field"
              :model-value="value"
              size="large"
              :disabled="wasValidated"
              :placeholder="$t('declared_expenditure')"
              :class='{ error: errors.length }'
              type="number"
              :suffix="currency"
              @focus="expenditureTooltipVisibility = true"
              @blur="expenditureTooltipVisibility = false"
              data-cy="declared_expenditure"
            )
          VVErrorMessage.vv-error(name="declared_expenditure" as="div")
          .h-4: span.tooltiptext(v-show="expenditureTooltipVisibility") {{ $t("tooltip_expenditure") }}

    template(v-if="showBgv")
      .screen-section
        .text-center {{ $t("bgv_text") }}
          p: a.tertiary(:href="BGV_LINK" target="_blank") {{ BGV_LINK }}
      .submit-section
        ElButton(
          class="w-auto"
          type="primary"
          :loading='loading'
          @click='goBackToMerchantWithError'
          data-cy="back_to_merchant"
        )
          .grow {{ $t("back_to_merchant") }}

    template(v-else)
      .screen-section
        .line_disclaimer
          span(v-html="$t('data_policy', { dataPolicyUrl })")
        .submit-section
          ElButton(
            class="w-1/2"
            type="primary"
            :disabled='!validateRevenueFields'
            :loading='loading'
            @click='handleSubmitClick'
            data-cy="submit"
          )
            .grow {{ $t("submit") }}

    SofincoLogo
</template>

<style scoped lang="scss">
.v-select:deep() {
  @apply mr-0 flex-1;
  .vs__search {
    @apply text-sm;
  }

  .vs__dropdown-toggle {
    @apply max-h-14 h-12;
    @apply border border-gray-300 items-center;
  }
  &.error {
    .vs__dropdown-toggle {
      @apply border border-failure;
    }

    .vs__search,
    .vs__selected {
      @apply text-failure;
    }
  }
}
</style>
