<script>
export default {
  name: "IconSourceTypeSofort",
};
</script>
<template lang="pug">
.pledg-icon.icon-source-type-sofort
  svg(viewBox="0 4 16 10" fill="none" xmlns="http://www.w3.org/2000/svg")
    path(d="M4.764 8.759c-.254 0-.432-.171-.432-.434 0-.291.22-.569.537-.569.254 0 .432.178.432.441a.543.543 0 0 1-.537.562ZM8.34 8.325c0 .263.178.434.432.434.317 0 .537-.267.537-.562 0-.263-.178-.44-.433-.44-.317 0-.536.277-.536.568ZM10.821 8.208h.087c.202 0 .342-.078.342-.224 0-.125-.098-.181-.283-.181h-.08l-.066.405Z" class="fill-black")
    path(fill-rule="evenodd" clip-rule="evenodd" d="M0 11.518h14.815L16 5H1.126L0 11.518Zm3.064-3.452c-.227-.118-.276-.146-.276-.21 0-.078.091-.117.213-.117.143 0 .359.017.572.224.052-.167.139-.317.25-.448-.3-.174-.578-.263-.832-.263-.506 0-.798.277-.798.611 0 .288.212.437.463.569.227.117.282.156.282.227 0 .078-.094.12-.22.12-.215 0-.466-.138-.616-.28l-.087.537c.153.113.376.23.714.23.523 0 .805-.27.805-.614 0-.284-.181-.437-.47-.586Zm1.85-.814c-.665 0-1.17.5-1.17 1.098 0 .54.414.917.979.917.665 0 1.167-.516 1.167-1.113.003-.543-.411-.902-.976-.902ZM7.751 8.35c0-.597.502-1.098 1.167-1.098.568 0 .983.359.98.902 0 .597-.502 1.113-1.168 1.113-.564 0-.98-.377-.98-.917Zm3.328-1.056c.502 0 .777.231.777.594 0 .302-.192.547-.516.66l.436.676h-.68l-.313-.608h-.028l-.098.608h-.592l.31-1.93h.704Zm-3.624.846h-.697l.055-.337h.746a1.34 1.34 0 0 1 .432-.509h-1.22a.536.536 0 0 0-.54.445l-.236 1.489h.592l.098-.608h.703l.053-.33c.003-.054.007-.1.014-.15Zm4.415-.846c.16.125.258.299.279.509h.495l-.227 1.421h.592l.227-1.421h.55l.08-.509H11.87Z" class="fill-black")
</template>
