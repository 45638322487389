<script>
import { mapGetters } from "vuex";
import { useOperator } from "@/composables/operator";

export default {
  name: "SofincoLogo",

  setup() {
    const { isCACFOperator } = useOperator();
    return { isCACFOperator };
  },
  computed: {
    ...mapGetters(["rawEmbedded"]),
  },
};
</script>

<template lang="pug">
.screen-section.flex-c-c(v-if="isCACFOperator && rawEmbedded")
  img(:src="'/static/images/logo_sofinco.svg'" width='100' height='')
</template>
