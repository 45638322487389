<template lang="pug">
.error-band.flex-c-c.flex-col(
  data-cy="error_screen"
)
  .error-container(data-cy="title")
    span.text-xl {{ $t(`error_screen.${title}`) }}
  .error-container(data-cy="subtitle")
    span {{ $t(`error_screen.${subtitle}`) }}

</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { AccessErrorCodes, AccessErrorTypes } from "@/constants";
import { MERCHANT } from "../../store/namespaces";

export default {
  computed: {
    ...mapGetters(MERCHANT, ["merchant"]),
    ...mapGetters(["accessErrorType", "rawMerchantUid"]),
  },
  async created() {
    switch (this.accessErrorType) {
      case AccessErrorTypes.EXPIRED:
        this.title = AccessErrorCodes.ERROR_410;
        this.subtitle = AccessErrorTypes.EXPIRED;
        break;
      case AccessErrorTypes.NOT_FOUND:
      default:
        this.title = AccessErrorCodes.ERROR_404;
        this.subtitle = AccessErrorTypes.NOT_FOUND;
    }

    if (this.rawMerchantUid) {
      await this.fetchMerchantAction({
        merchantUid: this.rawMerchantUid,
      });
    }
  },
  methods: {
    ...mapActions(MERCHANT, ["fetchMerchantAction"]),
  },
};
</script>
