<script>
import { mapGetters } from "vuex";

export default {
  name: "ScreenTitle",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["rawEmbedded"]),
  },
};
</script>

<template lang="pug">
.screen-title(v-html='title' v-if='!$isMobile() && !rawEmbedded')
</template>

<style lang="scss">
.screen-title {
  @apply mt-12 text-3xl font-semibold;
  @apply text-[var(--el-text-color-regular)];
  span {
    @apply text-[var(--el-color-primary)];
  }
}
</style>
