<template lang="pug">
.payment-solution-row.btn-light.flex.items-center.justify-between.p-3.h-20(:class='{active: active}' @click='selectMerchantUid')
  .flex.items-center.flex-1.h-full
    component(:is="paymentTypeIcon")
  .details.text-right.flex-3
    .payment-schedule
      span.text-lg {{ paymentScheduleSummary }}
    .fees
      span.text-xs {{ feesSummary }}
</template>
<script>
import { PaymentTypes } from "@/constants";
import { parseAndFormatDate } from "@/helpers/date";
import getFormattedPrice from "@/helpers/getFormattedPrice";
import { Logger } from "../../../utils/logger";

export default {
  name: "PaymentSolutionRow",
  props: {
    merchant: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["payment-solution-selected"],
  computed: {
    paymentType() {
      return this.merchant.payment_type;
    },
    paymentTypeIcon() {
      let iconName = this.paymentType.toLowerCase();
      if (this.paymentType === PaymentTypes.INSTALLMENT) {
        iconName += `-${this.merchant.installments_nb}x`;
      }
      return `icon-${iconName}`;
    },
    simulationData() {
      return this.merchant.simulation[this.paymentType];
    },
    sharesEffectiveAmountCents() {
      if (this.paymentType !== PaymentTypes.INSTALLMENT) {
        return [this.simulationData.amount_cents + this.simulationData.fees];
      }
      const shares_effective_amount_cents = this.simulationData.map(
        (shareData) => shareData.amount_cents + shareData.fees
      );
      return Array.from(new Set(shares_effective_amount_cents));
    },
    isPaymentHomogeneous() {
      return this.sharesEffectiveAmountCents.length === 1;
    },
    paymentScheduleSummary() {
      let label = "unknown";
      const params = {};

      switch (this.paymentType) {
        case PaymentTypes.DEFERRED:
          if (this.merchant.delay_in_days) {
            label = "payment_schedule_deferred_delay_in_days";
          } else if (this.merchant.day_of_next_month) {
            label = "payment_schedule_deferred_day_of_next_month";
          }
          params.payment_date = parseAndFormatDate(this.simulationData.payment_date, {
            formatDateFormat: "PPP",
            formatLocale: this.$i18n.locale,
          });
          break;
        case PaymentTypes.INSTALLMENT:
          if (this.isPaymentHomogeneous) {
            label = "payment_schedule_installment_homogeneous";
            params.installments_nb = this.merchant.installments_nb;
          } else {
            label = "payment_schedule_installment_disparate";
            params.installments_nb = this.merchant.installments_nb - 1;
            params.second_amount = getFormattedPrice(
              this.sharesEffectiveAmountCents[1],
              this.merchant.currency,
              this.$i18n.locale
            );
          }
          break;
        default:
          Logger.warn({
            id: "payment-schedule-summary-missing",
            payment_type: this.paymentType,
            merchant_uid: this.merchant.uid,
            delay_in_days: this.merchant.delay_in_days,
            day_of_next_month: this.merchant.day_of_next_month,
            installments_nb: this.merchant.installments_nb,
            is_payment_homogeneous: this.isPaymentHomogeneous,
          });
          break;
      }
      return this.$t(label, {
        amount: getFormattedPrice(
          this.sharesEffectiveAmountCents[0],
          this.merchant.currency,
          this.$i18n.locale
        ),
        delay_in_days: this.merchant.delay_in_days,
        ...params,
      });
    },
    feesSummary() {
      return this.$t("with_fees_amount", {
        amount: getFormattedPrice(
          this.feesAmountCents,
          this.merchant.currency,
          this.$i18n.locale
        ),
      });
    },
    feesAmountCents() {
      switch (this.paymentType) {
        case PaymentTypes.DEFERRED:
          return this.simulationData.fees;
        case PaymentTypes.INSTALLMENT:
          return this.simulationData[0].fees;
        default:
          Logger.warn({
            id: "payment-solution-fees-missing-case",
            payment_type: this.paymentType,
            merchant_uid: this.merchant.uid,
          });
          return 0;
      }
    },
  },
  methods: {
    selectMerchantUid() {
      this.$emit("payment-solution-selected", this.merchant.uid);
    },
  },
};
</script>
