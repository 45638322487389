<script>
import { mapGetters } from "vuex";
import { PURCHASE, SHARE } from "@/store/namespaces";
import Checkout from "@/components/screens/CheckoutComponents/Checkout.vue";
import { ordinal } from "@/helpers/date";
import LocalizedAmount from "@/components/LocalizedAmount.vue";
import { useFunnelStep } from "@/composables/funnelStep";
import { useShare } from "@/composables/share";
import { useRouter } from "@/composables/router";

export default {
  name: "ShareCheckout",
  components: {
    Checkout,
    LocalizedAmount,
  },

  setup(_, ctx) {
    const { funnelStepDone } = useFunnelStep(ctx);
    const {
      balancePaymentDate,
      depositPaymentDate,
      firstInstallmentPaymentDate,
      deferredPaymentDate,
      formattedAmountToCharge,
      formattedAmount,
      ancvFormattedAmount,
      balanceFormattedAmount,
      downPaymentFormattedAmount,
      formattedFeesAmount,
      formattedPenaltiesAmount,
      nextInstallmentsDetails,
      handleBackClick,
    } = useShare(ctx);
    const { isPaymentMethodUpdateRoute } = useRouter();

    return {
      balancePaymentDate,
      depositPaymentDate,
      firstInstallmentPaymentDate,
      deferredPaymentDate,
      formattedAmountToCharge,
      formattedAmount,
      ancvFormattedAmount,
      balanceFormattedAmount,
      downPaymentFormattedAmount,
      formattedFeesAmount,
      formattedPenaltiesAmount,
      nextInstallmentsDetails,
      handleBackClick,
      funnelStepDone,
      isPaymentMethodUpdateRoute,
    };
  },

  computed: {
    ...mapGetters(["rawIsSeminal", "rawEmbedded"]),
    ...mapGetters(PURCHASE, ["purchase", "penaltiesPercentage", "triggerable_capture"]),
    ...mapGetters(SHARE, [
      "share",
      "isLeader",
      "isAlone",
      "isFirstInstallment",
      "isNextInstallment",
      "isDeferred",
      "isDeposit",
      "isInstallment",
      "has_penalties",
      "has_fees",
      "ancvShare",
      "isBalance",
    ]),
    allInstallments() {
      return [
        {
          id: "first",
          deadline: this.firstInstallmentPaymentDate,
          amount: this.formattedAmountToCharge,
        },
        ...this.nextInstallmentsDetails,
      ];
    },
  },
  methods: {
    ordinal(number) {
      return ordinal(number, this.$i18n.locale);
    },
  },
};
</script>

<template lang="pug">
Checkout(
    @stepDone="funnelStepDone"
    :targetUid='share.uid', 
    :amountCents='share.amount_cents_to_charge' 
    :currency='share.currency'
)
    template(v-slot:title-header)
        .screen-section
            .go-back(v-if='isLeader && rawIsSeminal' @click='handleBackClick')
                | &lt;

            template(v-if='isFirstInstallment')
                h1 {{ $t("amount_to_pay") }} : {{ formattedAmountToCharge }}
                h2(v-if='rawIsSeminal')
                    span {{ $t('total_purchase_amount') }} :
                    LocalizedAmount(:localizedAmount='purchase.localized_effective_total_amount')

            template(v-else-if='isDeferred')
                h1 {{ $t("basket_amount") }} {{ formattedAmountToCharge }}
                h2(v-if='has_fees') {{ $t("with_fees_amount", { amount: formattedFeesAmount }) }}
                h2(v-if='has_penalties') {{ $t("penalties_detail", { amount: formattedPenaltiesAmount, penalties_percentage: penaltiesPercentage }) }}
                
                template(v-if='triggerable_capture')
                    h2 {{ $t("ill_pay_later_on_delivery", { date: deferredPaymentDate }) }}
                template(v-else)
                    h2 {{ $t("ill_pay_later", { date: deferredPaymentDate }) }}

            template(v-else)
                template(v-if='isLeader') 
                    h1 {{ $t("input_bank_details") }}
                    
                    template(v-if='isAlone')
                        h2 {{ $t("amount_to_pay") }} : {{ formattedAmountToCharge }}
                    template(v-else)
                        h2 {{ $t("total_purchase_amount") }} : {{ formattedAmountToCharge }}
                        h2 {{ $t("split_explanation") }} {{ formattedAmount }}

                h1(v-else-if='isPaymentMethodUpdateRoute')
                    | {{ $t('i_change_my_payment_method') }}

                h1(v-else-if='isNextInstallment')
                    | {{ $t("i_pay_my_installment") }} : {{ formattedAmountToCharge }}
                h1(v-else-if='isDeposit')
                    | {{ $t("amount_to_pay") }} : {{ downPaymentFormattedAmount }}
                h1(v-else)
                    | {{ $t("i_pay_my_share") }} : {{ formattedAmountToCharge }}

                template(v-if="isPaymentMethodUpdateRoute")
                    h2 {{ $t('payment_method_fingerprint_amount', { amount: formattedAmountToCharge }) }}
                template(v-else)
                    h2(v-if='has_fees')
                        | {{ $t("with_fees_amount", { amount: formattedFeesAmount }) }}
                    h2(v-if='has_penalties')
                        | {{ $t("penalties_detail", { amount: formattedPenaltiesAmount, penalties_percentage: penaltiesPercentage }) }}


    template(v-slot:timetable)
        .screen-section.pt-0(v-if='ancvShare')
            | {{ $t("amount_paid_in_ancv") }}
            span.text-right.float-right
                | {{ ancvFormattedAmount }}
                br

        .screen-section.pt-0(v-if='isFirstInstallment')
            div(class="timetable")
                div(v-for='(installment, index) of allInstallments' :key='`clear-${installment.id}`' :class="{ 'bg-light-blue': index % 2 === 0, 'bg-default': index % 2 === 1 }" class="timetable-row")
                    p.deadline.timetable-element(:key='`details-${installment.id}`')
                        span.float-left
                            span {{ index + 1 }}
                            sup {{ ordinal(index + 1) }}
                            span {{ $t("deadline_on") }} {{ installment.deadline }}
                        span.float-right.ml-auto.amount(v-if="index === 0" class="flex-column") {{ installment.amount }}
                            br
                            span(v-if='has_fees')
                                span(style='font-size: 0.85em')
                                    | {{ $t("with_fees_amount", { amount: formattedFeesAmount }) }}
                                    br

                            span(v-if='has_penalties')
                                span(style='font-size: 0.85em')
                                    | {{ $t("penalties_detail", { amount: formattedPenaltiesAmount, penalties_percentage: penaltiesPercentage }) }}
                        span.float-right.ml-auto.amount(v-else) {{ installment.amount }}

                div(style='clear: both')

        .screen-section.pt-0(v-if='isDeposit')
            p
                span.float-left(data-cy="deposit_deadline_on")
                    | {{ $t("deposit_deadline_on") }} {{ depositPaymentDate }}
                span.text-right.float-right(data-cy="deposit_deadline_amount")
                    | {{ formattedAmountToCharge }}
                    br

            div(style='clear: both; margin-bottom: 30px')

            p
                span.float-left(data-cy="balance_deadline_on")
                    | {{ $t("balance_deadline_on") }} {{ balancePaymentDate }}
                span.text-right.float-right(data-cy="balance_deadline_amount")
                    | {{ balanceFormattedAmount }}
                    br

            div(style='clear: both')

    template(v-slot:notice)
        .screen-section(v-if='rawIsSeminal && isLeader && !isAlone')
            .split_default_message
                span {{ $t("split_default_message") }}
        .mb-4(v-else)

    template(v-slot:cta)
        template(v-if='isInstallment || isDeposit || isDeferred')
            .line_disclaimer(v-if='purchase.localized_taeg && rawIsSeminal')
                span {{ $t('taeg', { num_of_days: purchase.loan_term_num_of_days, amount: formattedFeesAmount, taeg: purchase.localized_taeg }) }}

</template>
