<template lang="pug">
ScreenTitle(:title="$t('title_payment')")

//- Checkout screen
.screen.checkout-screen.min-w-128
  slot(name='title-header')

  .screen-section.section-card-infos(:style="{minHeight: shareIsSkipCard ? '0px' : (formReady ? '180px' : '100px')}")
    //- Stripe checkout form
    StripeElement(
      ref='stripeElement'
      :targetUid='targetUid'
      :amountCents='amountCents'
      :currency='currency'
      @stripe_element_ready='() => formReady = true'
      @stripe_element_form_complete='($event) => formComplete = $event'
      @stripe_element_selected_psp_payment_method='($event) => selectedPspPaymentMethod = $event'
    )

  slot(name='timetable')

  slot(name='notice')

  .screen-section.cta-section.submit-section(v-if='!rawExternalCheckoutValidation')
    slot(name='cta')
    .line_disclaimer.px-4.py-2(v-if='isAnticipatedPayment')
      span.text-base.text-black(
        v-html="$t('anticipated_payment_warning', { date: anticipatedPaymentDetails.deadlineLong, days: anticipatedPaymentDetails.days })"
      )

    .line_disclaimer(v-if='rawIsSeminal')
      span(v-html="$t('data_policy', { dataPolicyUrl })")
      template(v-if='displayCGULink')
        | &nbsp;
        i18n-t(keypath='accept_cta_installment' slot="label")
          CGULink
            | {{ $t("notice_payment") }}

    .line_disclaimer(
      v-if="enableAdvertisementOptin && rawIsSeminal"
      data-cy="advertisement-optin"
    )
      label.flex.items-top.cursor-pointer.justify-start
        el-checkbox.h-min.pr-2(
          v-model="advertisementOptin"
        )
        span.text-justify {{ $t('advertisement_optin', { optin_advertiser: this.merchantOptinAdvertiser }) }}

    .line_disclaimer(v-if="displayContractAndMarketingDecision")
      template(v-if="mustSignContract")
        label.flex.items-top.cursor-pointer.justify-start.mb-2(:class='{ "line_disclaimer_error": this.contractNotAcceptedError }')
          el-checkbox.h-min.pr-2(
            v-model="contractAcceptance"
            data-cy="contract_acceptance"
          )
          span.text-justify {{ $t('contract_message_start') }}
            a(@click="downloadContract" data-cy="contract_link") {{ this.contractMessageLink }}
            span {{ $t('contract_message_end') }}
        .vv-error(v-if='contractDownloadError') {{ $t("contractDownloadError") }}

      template(v-if="is_eligible_for_marketing_decision")
        label.flex.items-top.cursor-pointer.justify-start.mb-2
          el-checkbox.h-min.pr-2(
            v-model="electronicOptin"
            data-cy="electronic-optin"
          )
          span.text-justify {{ $t('electronic_optin') }}
        label.flex.items-top.cursor-pointer.justify-start
          el-checkbox.h-min.pr-2(
            v-model="phoneMailOptout"
            data-cy="phone-mail-optout"
          )
          span.text-justify {{ $t('phone_mail_optout') }}
    
    .vv-error(v-if='contractSignError') {{ $t("contractSignError") }}

    ElButton.mt-1(
      class="w-1/2"
      type="primary"
      :disabled='!canSubmit'
      :loading='submitting'
      @click='handleSubmitClick'
      data-cy="submit"
    )
      .grow {{ $t("submit") }}
      .el-icon--right.text-3xl(v-if="!isCACFOperator") &rsaquo;

  .screen-section.section-secure
    template(v-if='rawEmbedded')
      IconLock
      span(v-t="'secured_payment'")

  .screen-section(v-if="!rawIsSeminal")
    .line_disclaimer
      span(v-html="$t('data_policy_link', { dataPolicyUrl })")
  
  SofincoLogo
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Sentry from "@/plugins/sentry";
import StripeElement from "@/components/StripeElement.vue";
import CGULink from "@/components/CGULink.vue";
import { ORDER, MERCHANT, PURCHASE, SHARE } from "@/store/namespaces";
import { PLUGIN_EVENTS } from "@/constants";
import { PspPaymentMethods } from "@/constants/stripe";
import ERROR_TYPES from "@/constants/errors";
import IconLock from "@/components/icons/IconLock.vue";
import PurchaseService from "@/api/purchaseService";
import { Logger } from "@/utils/logger";
import { useFunnelStep } from "@/composables/funnelStep";
import { useError } from "@/composables/error";
import { useRouter } from "@/composables/router";
import { usePlugin } from "@/composables/plugin";
import { useOperator } from "@/composables/operator";
import { useAnticipatedPayment } from "@/composables/anticipatedPayment";
import { downloadPDF, getFilenameFromResponse } from "@/helpers/downloadFile";
import SofincoLogo from "@/components/SofincoLogo.vue";
import ScreenTitle from "@/components/ScreenTitle.vue";

export default {
  name: "CheckoutForm",
  components: {
    StripeElement,
    IconLock,
    CGULink,
    SofincoLogo,
    ScreenTitle,
  },
  beforeRouteUpdate(to, from, next) {
    if (to.query.error) {
      this.errorHuman = to.query.error;
    }
    this.postMessageParent(PLUGIN_EVENTS.PLEDG_SCROLL_TO_TOP);
    next();
  },
  props: {
    targetUid: {
      type: String,
      required: true,
    },
    amountCents: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  setup(_, ctx) {
    const { funnelStepDone } = useFunnelStep(ctx);
    const { handleError } = useError();
    const { isPaymentMethodUpdateRoute } = useRouter();
    const { postMessageParent, listenExternalCheckoutValidation } = usePlugin();
    const { anticipatedPaymentDetails, isAnticipatedPayment } = useAnticipatedPayment();
    const { isCACFOperator } = useOperator();
    return {
      handleError,
      funnelStepDone,
      anticipatedPaymentDetails,
      isPaymentMethodUpdateRoute,
      isAnticipatedPayment,
      postMessageParent,
      listenExternalCheckoutValidation,
      isCACFOperator,
    };
  },

  data() {
    return {
      formReady: false,
      formComplete: false,
      submitting: false,
      paymentMethod: undefined,
      advertisementOptin: undefined,
      electronicOptin: false,
      phoneMailOptout: false,
      selectedPspPaymentMethod: undefined,
      errorHuman: undefined,
      contractAcceptance: false,
      contractDownloadError: false,
      contractNotAcceptedError: false,
      contractSignError: false,
    };
  },
  computed: {
    ...mapGetters([
      "rawIsSeminal",
      "rawExternalCheckoutValidation",
      "rawPaymentMethodId",
      "rawEmbedded",
    ]),
    ...mapGetters(ORDER, ["orderIsMoto", "orderUid"]),
    ...mapGetters(MERCHANT, [
      "merchant",
      "merchantOptinAdvertiser",
      "enableAdvertisementOptin",
      "displayCGULink",
      "dataPolicyUrl",
      "isInstallmentPayment",
    ]),
    ...mapGetters(PURCHASE, [
      "purchaseUid",
      "is_eligible_for_marketing_decision",
      "mustSignContract",
    ]),
    ...mapGetters(SHARE, ["shareUid", "shareIsSkipCard", "shareIsMoto"]),
    //
    paymentMethodId() {
      return this.shareIsSkipCard ? this.rawPaymentMethodId : this.paymentMethod?.id;
    },
    isCardPayment() {
      return this.selectedPspPaymentMethod === PspPaymentMethods.CARD;
    },
    isSepaDebitPayment() {
      return this.selectedPspPaymentMethod === PspPaymentMethods.SEPA_DEBIT;
    },
    canSubmit() {
      if (this.submitting) return false;
      if (this.shareIsSkipCard) return true;
      // Stripe element sepa debit may still have an error message
      return (
        this.formComplete &&
        (this.isSepaDebitPayment || !this.$refs?.stripeElement.errorMessage)
      );
    },
    contractMessageLink() {
      return this.isInstallmentPayment
        ? this.$t("contract_message_link_installment")
        : this.$t("contract_message_link_deferred");
    },
    displayContractAndMarketingDecision() {
      return this.canUpdateMarketingDecision || this.canSignContract;
    },
    canSignContract() {
      return this.mustSignContract && this.rawIsSeminal;
    },
    canSetAdvertisementOptin() {
      return this.enableAdvertisementOptin && this.rawIsSeminal;
    },
    canUpdateMarketingDecision() {
      return this.is_eligible_for_marketing_decision && this.rawIsSeminal;
    },
  },
  watch: {
    canSubmit(newValue) {
      this.postMessageParent(PLUGIN_EVENTS.PLEDG_CHECKOUT_FORM_READINESS, {
        readiness: newValue,
      });
    },
    formComplete(newValue) {
      if (newValue) {
        this.$refs.stripeElement.errorMessage = undefined;
      }
    },
  },
  mounted() {
    this.listenExternalCheckoutValidation(this.handleSubmitClick);
    this.postMessageParent(PLUGIN_EVENTS.PLEDG_SCROLL_TO_TOP);
  },
  methods: {
    ...mapActions(["setPaymentMethodRegistered"]),
    async downloadContract() {
      this.contractDownloadError = false;
      try {
        const res = await PurchaseService.fetchContract(
          this.purchaseUid,
          {},
          {
            responseType: "blob",
          }
        );
        const filename = getFilenameFromResponse(res);
        downloadPDF(res.data, filename);
      } catch (error) {
        this.contractDownloadError = true;
      }
    },
    async handleSubmitClick() {
      if (!this.canSubmit) {
        return;
      }
      this.contractNotAcceptedError = false;
      this.contractDownloadError = false;
      this.contractSignError = false;

      if (this.canSignContract && !this.contractAcceptance) {
        this.contractNotAcceptedError = true;
        return;
      }

      this.submitting = true;
      const { success, paymentMethodError } = await this.createPaymentMethod();
      if (paymentMethodError && !success) {
        this.submitting = false;
        Logger.error({
          id: "create-payment-method-error",
          error: paymentMethodError,
        });
        return;
      }

      Logger.info({
        id: "submit-payment",
        selectedPspPaymentMethod: this.selectedPspPaymentMethod,
      });

      this.postMessageParent(PLUGIN_EVENTS.PLEDG_CHECKOUT_SUBMIT_START);

      if (this.canSignContract) {
        try {
          await PurchaseService.signContract(this.purchaseUid);
        } catch (error) {
          this.contractSignError = true;
          this.submitting = false;
          return;
        }
      }

      if (this.canSetAdvertisementOptin) {
        await PurchaseService.setAdvertisementOptin(
          this.purchaseUid,
          this.advertisementOptin
        );
      }

      if (this.canUpdateMarketingDecision) {
        await PurchaseService.updateMarketingDecision(
          this.purchaseUid,
          this.electronicOptin,
          this.phoneMailOptout
        );
      }

      /**
       * Create PaymentIntent using the paymentMethod supplied or created
       * When payment is refused, the payment funnel is aborted
       */
      let paymentIntentResult;
      try {
        Logger.info({
          id: "card-create-payment-intent",
          shareIsSkipCard: this.shareIsSkipCard,
          paymentMethodId: this.paymentMethodId,
        });

        this.setPaymentMethodRegistered();
        paymentIntentResult = await this.$refs.stripeElement.createPaymentIntent(
          this.paymentMethodId,
          this.isPaymentMethodUpdateRoute
        );
      } catch (apiResponseError) {
        const { statusCode, backError, errorHuman } = apiResponseError;
        if (
          statusCode === 400 &&
          [ERROR_TYPES.REFUSED_PAYMENT, ERROR_TYPES.TRANSACTION_TIMEOUT].includes(
            backError?.app_error
          )
        ) {
          const message = errorHuman || this.$t("paymentRefused");
          let errorId;
          let errorType;

          if (backError?.app_error === ERROR_TYPES.REFUSED_PAYMENT) {
            errorId = "create_payment_intent_payment_refused";
            errorType = ERROR_TYPES.PAYMENT_REFUSED;
          } else if (backError?.app_error === ERROR_TYPES.TRANSACTION_TIMEOUT) {
            errorId = "create_payment_intent_transaction_timeout";
            errorType = ERROR_TYPES.TRANSACTION_TIMEOUT;
          }
          this.handleError(errorId, errorType, message, {
            displayedError: {
              title: message,
            },
            reportError: false,
          });
        }
        this.postMessageParent(PLUGIN_EVENTS.PLEDG_CHECKOUT_SUBMIT_END);
        this.submitting = false;
        return;
      }

      /**
       * Confirm the payment using the preferred network
       */
      if (!(this.orderIsMoto || this.shareIsMoto)) {
        try {
          Logger.info({ id: "confirm-payment", type: this.selectedPspPaymentMethod });

          await this.$refs.stripeElement.confirmPayment({
            clientSecret: paymentIntentResult.pspClientSecret,
            payment_method: paymentIntentResult.pspPaymentMethodId,
          });
        } catch (error) {
          this.postMessageParent(PLUGIN_EVENTS.PLEDG_CHECKOUT_SUBMIT_END);
          this.submitting = false;
          return;
        }
      }

      this.funnelStepDone();
    },
    async createPaymentMethod() {
      if (this.shareIsSkipCard) {
        return { success: true };
      }
      if (!this.formComplete) {
        return { success: false, paymentMethodError: "Form incomplete" };
      }

      const isValid = await this.$refs.stripeElement.validateForm();
      if (!isValid) return { success: false, paymentMethodError: "Form invalid" };

      const { error, paymentMethod } =
        await this.$refs.stripeElement.createPaymentMethod();
      if (error) return { success: false, paymentMethodError: error };

      this.paymentMethod = paymentMethod;

      if (this.isCardPayment) {
        const availableNetworks = paymentMethod?.card?.networks?.available;
        if (!availableNetworks) {
          Sentry.info("missing_payment_method_networks", {
            ...paymentMethod,
            purchaseUid: this.purchaseUid,
            shareUid: this.shareUid,
            selectedPspPaymentMethod: this.selectedPspPaymentMethod,
          });
        }
      }
      return { success: true };
    },
  },
};
</script>
